<template>
  <section class="maker head smart-wrap" v-if="record != null && mode == 'sml' && modeMaker == 'design'" >
    <div class="col-md-4" style="margin: 0 0 20px 0;">
      <h2 v-if="record.name" class="title-g">{{record.name}}</h2>
      <h2 v-else class="title-g">Layout Name</h2>
      <p v-if=" record.SK == '' || record.SK == undefined" class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">{{ record.PK}}: (New {{record.entityName}})</p>
      <p v-else class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">{{record.PK}}: {{ record.entityName+ '-'+ record.friendlyId}}</p>
      <div v-if="userPermissions.includes('Superadmin') || userPermissions.includes('Admin')" class="collapse" id="infoItem">
        <div class="card card-body">
          <p style="color: #949494; margin:0; font-size: 12px; padding: 0;">  <strong>PK: </strong> {{record.PK}} </p>
          <p style="color: #949494; margin:0; font-size: 12px; padding: 0;">  <strong>SK: </strong> {{record.SK}} </p>
          <p style="color: #949494; margin:0; font-size: 12px; padding: 0;" v-show=" index != 'strAtt'" v-for="(att, index) in record" :key="index">  <strong>{{index+': '}} </strong> <paper-displayer :value="att"/> </p>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <ui-button style="margin: 0 0 0 10px;" :color="'red'" :position="'right'" :text="'Back'" v-on:click="backMaker()"/>
      <span v-if="record.shortEntity == '#SML'">
        <ui-button style="margin: 0 0 0 10px;" :color="'green'" :position="'right'" :text="'Save and Back'" v-if="record.SK" v-on:click="updateLayout()" />
        <ui-button :color="'green'" :position="'right'" :text="'Save'" v-if="record.SK" v-on:click="updateLayout()" />
        <ui-button style="margin: 0 0 0 10px;" :color="'green'" :position="'right'" :text="'Created and Back'" v-if="!record.SK" v-on:click="updateLayout()" />
        <ui-button :color="'green'" :position="'right'" :text="'Save'" v-if="!record.SK" v-on:click="updateLayout()" />
      </span>
      <span v-else>
        <ui-button style="margin: 0 0 0 10px;" :color="'green'" :position="'right'" :text="'Created and Back'"  v-on:click="createSMC(customLayout[0])" />
        <ui-button :color="'green'" :position="'right'" :text="'Save SMC'" v-on:click="createSMC(customLayout[0])" />
      </span>
    </div>
    <!-- Cofig Record -->
    <div class="input-wrap col-md-2">
      <label for="">System Type</label>
      <select  v-if="userPermissions.includes('Superadmin')" name="" id="" v-model="record.PK">
        <option selected disabled hidden>select an option</option>
        <option value="#SMARTDASH">System</option>               
        <option :value="org.value" v-for="(org, index) in preloadLists['#ORG']" :key="index">
          {{ org.value.replace("ORG#", "") }}
        </option>               
      </select>
      <select  v-else name="" id="" v-model="record.PK">
        <option selected disabled hidden>select an option</option>
        <option value="#SMARTDASH">System</option>               
        <option :value="organizationID" >
          {{ organizationID.replace("ORG#", "") }}
        </option>               
      </select>
    </div>
    <div class="input-wrap col-md-3">
      <label for="">Name Of Smart Layout</label>
      <input type="text" v-model="record.name" />
    </div>
    <div class="input-wrap col-md-2">
      <label for="">Attribute Name</label>
      <input type="text" v-model="record.attributeName" />
    </div>
    <div class="input-wrap col-md-2">
      <label for="">Layout Category</label>
      <select  v-model="record.category">
        <option selected disabled hidden>select an option</option>
        <option value="form">Form</option>
        <option value="menu">Menu</option>
        <option value="post">Post</option>  
        <option value="webpage">Webpage</option>
        <option value="quiz">Quiz</option>
        <option value="smartForm">Smart Form</option>
      </select>
    </div>
    <div class="input-wrap col-md-3">
      <label for="">Parent SML</label>
      <div class="dropdown show">
        <a  class="dropdown-toggle inputMimo" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 10px; cursor:pointer;" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span v-if="!record.parent" >
            Select a Option
          </span> 
          <span v-else  >
            {{record.parent.value}}
          </span> 
        </a>
        <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
          <a class="dropdown-item" :style="'display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;'" v-on:click="record.parent = {PK: sml.PK, SK: sml.SK, value: sml.attributes['smartlayout.name'].attributeValue }" v-for="(sml, index) in customPanel.smlList" :key="index"> 
            {{ sml.attributes['smartlayout.name'].attributeValue }} 
          </a>
          <a class="dropdown-item" :style="'display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;'" v-on:click="record.parent = undefined" > 
            None
          </a>
        </div>
      </div>
    </div>
    <div class="input-wrap col-2"> 
      <label for="">Template</label>
      <input type="text" v-model="record.template">
    </div>
    <div class="input-wrap col-2">
      <label for="">Path</label>
      <input type="text" v-model="record.path">
    </div>
    <div class="input-wrap col-2">
      <label for="">Template</label>
      <select name="" id="" v-model="record.templateType">
        <option value="hf">Header - Footer</option>
        <option value="body">Body</option>
      </select>
    </div>
    <div class="input-wrap col-2">
      <label for="">App Code</label>
      <input type="text" v-model="record.appCode">
    </div>
    <div class="input-wrap col-4">
      <label for="">Description</label>
      <input type="text" v-model="record.description">
    </div>
    <div class="input-wrap col-md-12" v-if="record.category =='webpage'" style=" display: flex; align-items: center; justify-content: flex-end; flex-wrap: nowrap;" >
      
      <ui-button text="" color="green" style="padding:6px 10px;  width: 200px; display: block; margin: 0 20px; color: #fff;" >    
        <router-link style="color:#fff;" :to="{ path:'/makerpreview',query: { PK:record.PK, SK:record.SK  } }" >
          Preview
        </router-link>
      </ui-button>

      <ui-button text="" color="green" style="padding:6px 10px;  width: 200px; display: block; margin: 0 20px; color: #fff;" >    
        <router-link style="color:#fff;" :to=" { path:'/webpreview',query: {  sml:record.SK,}  }" >
          Input Data
        </router-link>
      </ui-button>

      <ui-button  text="Publish Page" color="green" style="padding:6px 10px;  width: 200px; display: block; margin: 0 20px;"  v-on:click="publishSML() ;"/>

    </div>
  </section>
  <section class="maker head smart-wrap" v-if="record != null && mode == 'sml' && modeMaker == 'generate'" >
    <div class="col-md-4" style="margin: 0 0 20px 0;">
      <h2 v-if="record.name" class="title-g">{{record.name}}</h2>
      <h2 v-else class="title-g">Layout Name</h2>
      <p v-if=" record.SK == '' || record.SK == undefined" class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">{{ record.PK}}: (New {{record.entityName}})</p>
      <p v-else class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">{{record.PK}}: {{ record.entityName+ '-'+ record.friendlyId}}</p>
      <div v-if="userPermissions.includes('Superadmin') || userPermissions.includes('Admin')" class="collapse" id="infoItem">
        <div class="card card-body">
          <p style="color: #949494; margin:0; font-size: 12px; padding: 0;">  <strong>PK: </strong> {{record.PK}} </p>
          <p style="color: #949494; margin:0; font-size: 12px; padding: 0;">  <strong>SK: </strong> {{record.SK}} </p>
          <p style="color: #949494; margin:0; font-size: 12px; padding: 0;" v-show=" index != 'strAtt'" v-for="(att, index) in record" :key="index">  <strong>{{index+': '}} </strong> <paper-displayer :value="att"/> </p>
        </div>
      </div>
    </div>
    <div class="col-md-8" style=" display: flex; align-items: center; justify-content: flex-end; flex-wrap: nowrap;">
      
      <ui-button style="margin: 0 0 0 10px; " :color="'red'" :position="'right'" :text="''" v-on:click="refresh()">
        <router-link  style="color: #fff;"  :to=" { path:'/webpreview',query: {  sml:record.SK }  }"   >
          Input Data 
        </router-link>
      </ui-button>
        <ui-button text="" color="green" style="padding:6px 10px;  width: 200px; display: block; margin: 0 20px; color: #fff;" >    
          <router-link style="color:#fff;" :to="{ path:'/makerpreview',query: { PK:record.PK, SK:record.SK  } }" >
            Preview
          </router-link>
        </ui-button>
      <ui-button style="margin: 0 0 0 10px;" :color="'red'" :position="'right'" :text="'Design'" v-on:click="refresh()"/>


      <!--ui-button :color="'green'" :position="'right'" :text="'Publish Page'"  v-on:click="updateLayoutAtt()" /-->
    </div>
  </section>
  <section class="maker body smart-wrap"  v-if="record != null && mode == 'sml' && modeMaker == 'design'" >
    <div  class="col-12" style="margin: 0 0 20px 0;">
      <div :style="'width:80px;height: 50px; float:left; display:block; background:'+item+';'" v-for="(item, index) in record.palette" :key="index" > </div>
    </div>
    <div class="list-group-item list-group-item-action " v-for="(version, index) in layoutVersions" :key="index" :style="setStyleVersion(index)">
      <div class="row">
        <div style="float:left; width: calc( 50% - 60px ); " class="input-wrap"> <input type="text" v-model="layoutVersions[index].name"> </div>
        <div style="float:left; width: calc( 50% - 60px ); " class="input-wrap"> 
          <select name="" id="" v-model="layoutVersions[index].format" v-on:change="setFrame(index)">
            <option value="none">None </option>
            <option value="draft">Draft</option>
            <option value="twiterPost">Twiter Post</option>
            <option value="twiterHeader">Twiter Header</option>
            <option value="facebookPost">Facebook Post</option>
            <option value="instagramPost">Instagram Post</option>
            <option value="instagramStory">Instagram Story</option>
            <option value="dribbbleShot">Dribbble Shot</option>
            <option value="linkedinCover">Linkedin Cover</option>
          </select>
        </div>
        <div class="options">
          <div class="col edit" style="float:rigth; cursor: pointer; " v-on:click="actionVersion('view', index)" >
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.55917 15.0159H5.68547C5.27612 15.016 4.87664 14.8901 4.54129 14.6554C4.20593 14.4206 3.95094 14.0884 3.81094 13.7037L0.0618791 3.39209C-0.0707341 3.02816 0.0200011 2.62036 0.293204 2.34617L0.585352 2.05403L6.98867 8.45717C6.98767 8.4831 6.98069 8.50902 6.98069 8.53494C6.98069 8.83074 7.06841 9.1199 7.23275 9.36585C7.3971 9.6118 7.63068 9.80349 7.90398 9.91669C8.17727 10.0299 8.47799 10.0595 8.76812 10.0018C9.05824 9.94409 9.32474 9.80165 9.53391 9.59249C9.74307 9.38332 9.88552 9.11683 9.94323 8.82672C10.0009 8.5366 9.97132 8.23589 9.85812 7.9626C9.74492 7.68932 9.55322 7.45574 9.30726 7.2914C9.06131 7.12706 8.77214 7.03935 8.47633 7.03935C8.45041 7.03935 8.42448 7.04633 8.39856 7.04732L1.99524 0.644182L2.28739 0.352044C2.42145 0.217648 2.59119 0.124444 2.77654 0.0834522C2.96189 0.0424605 3.1551 0.0553948 3.33334 0.120725L13.6453 3.87068C14.0299 4.01068 14.3622 4.26566 14.5969 4.601C14.8317 4.93635 14.9575 5.33581 14.9574 5.74516V9.61875L16.9506 11.6109L11.5524 17.009L9.55917 15.0159V15.0159ZM17.8879 12.9599L12.9024 17.9452L15.0163 20.06L20.0017 15.0747L17.8879 12.9599Z" fill="white"/>
            </svg>
          </div>
          <div class="col delete" style="float:rigth; cursor: pointer;" v-on:click="actionVersion('delete', index)" >
            <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
              <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-8 constructor">
      <!--paper-layers v-if="record.category == 'menu'" :src="record" :data="customLayout" /-->
      <paper-constructor  :listDrop="customLayout" :palette="record.palette" :styleDragg="setSetyleBoddy(record.bodyStyle)" :idioms="record.idioms" :language="customPanel.language" />
    </div>
    <div class="col-md-4 custom" style="z-index: 0; padding:0 0 0 20px;">
      <div class="customPanel middleShadow" id="stickyPanel">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="components-tab" data-toggle="tab" href="#components" role="tab" aria-controls="components" aria-selected="true">Components</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="layers-tab" data-toggle="tab" href="#layers" role="tab" aria-controls="layers" aria-selected="false">Layers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="assets-tab" data-toggle="tab" href="#assets" role="tab" aria-controls="assets" aria-selected="false">Assets</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="config-tab" data-toggle="tab" href="#config" role="tab" aria-controls="config" aria-selected="false">Config</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="config-tab" data-toggle="tab" href="#help" role="tab" aria-controls="help" aria-selected="false">Help</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade components show active" id="components" role="tabpanel" aria-labelledby="components-tab">
            <div class="input-wrap col-md-12">
              <label for="">Components</label>
              <select v-model="customPanel.focusComponentType"  v-on:change="getComponents(customPanel.focusComponentType)">
                <option selected>Select an Option</option>
                <option value="#MEL">Meta Elements</option>
                <option value="#PAG">Modules</option>
                <option value="#SMC">Smart Components</option>
              </select>
            </div>
            <div class="input-wrap col-md-12">
              <label for="">Search for names..</label>
              <input type="text" id="searchComponent" v-on:keyup="searchComponent()" v-model="customPanel.search" />
            </div>
            
            <draggable  v-bind="dragOptions" id="listSmart" class="dragArea list-group fronter smart-wrap" style="width: 100%;" :list="customPanel.componentList" :group="{ name: 'components', pull: 'clone', put: false }" :clone="dropComponent" @change="onDrop()"  item-key="no" >
              <template #item="{ element }">
                <div class="list-group-item miniShadow" style="width: 100%;">
                  <div  class="btn btn-custom" style="opacity: 1;" data-toggle="modal" :data-target="'#modal-update'" v-on:click="smartComponentEdit(element);"><i class="bx bx-dots-vertical-rounded"></i></div>
                  
                  <p data-toggle="collapse" :href="'#collapse-'+element.attributeName" role="button" aria-expanded="false" aria-controls="collapseExample">
                    {{element.name}} 
                    <img v-if="element.preview && element.preview[0]" :src="element.preview[0].url" style="width: 100%;" :alt="element.preview[0].url">
                  </p>
                  <div class="collapse" :id="'collapse-'+element.attributeName">
                    <div class="card card-body">
                      <!--paper-displayer :value="element" /-->
                    </div>
                  </div>
                </div>
              </template>
            </draggable>
          </div>
          <div class="tab-pane fade layers" id="layers" role="tabpanel" aria-labelledby="layers-tab">
            <paper-layers :src="record" v-if="customLayout != null" :data="customLayout" />
          </div>
          <div class="tab-pane fade config " id="assets" role="tabpanel" aria-labelledby="assets-tab">
            <div class="input-wrap col-md-12">
              <label for="">Asset Organization</label>
              <select  v-if="userPermissions.includes('Superadmin')" name="" id="" v-model="customPanel.PK" v-on:change="listAssets()">
                <option selected disabled hidden>select an option</option>
                <option value="#SMARTDASH">System</option>               
                <option :value="org.value" v-for="(org, index) in preloadLists['#ORG']" :key="index">
                  {{ org.value.replace("ORG#", "") }}
                </option>               
              </select>
              <select  v-else name="" id="" v-model="customPanel.PK" v-on:change="listAssets()"> 
                <option selected disabled hidden>select an option</option>
                <option value="#SMARTDASH">System</option>               
                <option :value="organizationID" >
                  {{ organizationID.replace("ORG#", "") }}
                </option>               
              </select>
            </div>
            <div class="smart-wrap">
              <draggable v-bind="dragOptions" id="listSmart" class="dragArea fronter smart-wrap" style="width: 100%;" :list="customPanel.assets" :group="{ name: 'components', pull: 'clone', put: false }" :clone="dropComponent" @change="onDrop()"  item-key="key" >
              <template #item="{ element }">
                <div class="list-group-item miniShadow col-md-6" style="display: flex; align-items: center; justify-content: center; padding:10px;">
                  <img :src="element.url" alt="" style="width: 100px;">
                </div>
              </template>
            </draggable>
            </div>
          </div>
          <div class="tab-pane fade config " id="config" role="tabpanel" aria-labelledby="config-tab">
            <ul class="list-group" v-if="customPanel.configStyle.length >0">
              <a :class="{ 'active': configStyleSelected == index,'list-group-item list-group-item-action':true }" style="" v-for="(config, index) in customPanel.configStyle" :key="index"   v-on:click="configStyleSelected= index; setConfigStyles(config)">
                {{ config.attributes.name.attributeValue }}
                <!--div  class="btn btn-custom" style="opacity: 1;" v-on:click="setConfigStyles(element)"><i class="bx bx-dots-vertical-rounded"></i></!--div-->
              </a>
            </ul>
            <div class="smart-wrap" style="background: #ffbc003d; margin:10px 0;">
              <div class="input-wrap col-md-12">
                <label for="">Css Class</label>
                <textarea name="" id="" cols="7" rows="7" v-model="record.class"></textarea>
              </div>
              <div class="input-wrap col-md-12">
                <ui-button text="Apply Css" color="blue" position="center"  style="margin:10px auto ;padding: 7px 20px; width:200px;" v-on:click="applyCss()"/>
              </div>
              
              <div class="input-wrap col-md-12">
                <label for="">Css Fonts</label>
                <textarea name="" id="" cols="7" rows="7" v-model="record.fontScript"></textarea>
              </div>
              <div class="input-wrap col-md-12" v-for="(font, index) in record.fonts" :key="index">
                <label for="">Font {{index}}</label>
                <input type="text" v-model="record.fonts[index]"> 
              </div>
            </div> 
            <div class="smart-wrap">
              <div class="input-wrap col-md-12">
                <label for="">Body Style (building)</label>
                <textarea name="" id="" cols="30" rows="10" v-model="record.bodyStyle"></textarea>
              </div>
              <div class="input-wrap col-md-12">
                <h6 for="" >Language</h6>
                <ul class="list-group list-languages" >
                  <li :class="{ 'list-group-item ':true, 'active': item == customPanel.language , } " v-for="(item, index) in record.idioms" :key="index" style="padding:10px 0px 10px 10px; display:block;" > 
                    <select name="" id="" v-model="record.idioms[index]" class="col-10" style="display:block; float:left; width:calc(100% - 100px);">
                      <option v-show="!record.idioms.includes(vindex)" :value="vindex" v-for="(idiom, vindex) in srcIdioms" :key="vindex">{{idiom}}</option>
                    </select> 
                    <div class="options" style="display:block; float:left; width:100px; " >
                      <ui-accion-button style="margin:0 0 0 10px; cursor:pointer;" action='view' v-on:click="customPanel.language = item"/>
                      <ui-accion-button style="margin:0 0 0 10px; cursor:pointer;" action='delete' v-on:click="record.idioms.splice(index, 1)"/>
                    </div>
                  </li> 
                  <li class="list-group-item add-table" style="text-align:center; cursor:pointer;" v-on:click="record.idioms.push('')" > 
                    Add New 
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="#5a5a5a" stroke-width="2"><path stroke-linecap="round" d="M12 8v4m0 0v4m0-4h4m-4 0H8"/><circle cx="12" cy="12" r="10"/></g></svg>
                  </li> 
                </ul>
                
              </div>
              <!--Colors-->
              <div class="input-wrap col-md-6">
                <label for="">Color 0</label>
                <input type="text"  v-model="record.palette[0]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[0]+';'"> </div>
              <div class="input-wrap col-md-6">
                <label for="">Color 1</label>
                <input type="text"  v-model="record.palette[1]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[1]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">Color 2</label>
                <input type="text"  v-model="record.palette[2]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[2]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">Color 3</label>
                <input type="text"  v-model="record.palette[3]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[3]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">Color 4</label>
                <input type="text"  v-model="record.palette[4]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[4]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">Black</label>
                <input type="text"  v-model="record.palette[5]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[5]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">White</label>
                <input type="text"  v-model="record.palette[6]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[6]+';'"></div>
              <!--Colors-->
            </div>
            <div class="elemet-wrap row col-md-12"  style="background: #ffbc003d;">
              <h6 class="col-md-12" style=" padding: 10px"> Permissions </h6>
              <div class="input-wrap col-md-4"> 
                <label for=""> Groups Can Edit</label> <br>
                <div class="envelop" v-for="(check, zindex) in permissions" :key="zindex">
                  <input type="checkbox" class="box" :value="check.value" v-model="record.groupsCanEdit"/>
                  <span class="text" >&nbsp; {{check.value}} </span>
                </div>
              </div> 
              <div class="input-wrap col-md-4"> 
                <label for="">Groups Can Read</label><br>
                <div class="envelop" v-for="(check, zindex) in permissions" :key="zindex">
                  <input type="checkbox" class="box" :value="check.value" v-model="record.groupsCanRead"/>
                  <span class="text" >&nbsp; {{check.value}} </span>
                </div>
              </div> 
              <div class="input-wrap col-md-4"> 
                <label for="">User Can Edit</label>
                <br>
                <input type="checkbox" class="box" value="@author" v-model="record.usersCanEdit"/>
                <span class="text" >&nbsp; @Author </span>
                <br>
                <input type="checkbox" class="box" value="@client" v-model="record.usersCanEdit"/>
                <span class="text" >&nbsp; @Client </span>
                <br>
                <input type="checkbox" class="box" value="@responsible" v-model="record.usersCanEdit"/>
                <span class="text" >&nbsp; @Responsible </span>
              </div> 
              <div class="input-wrap col-md-4"> 
                <label for="">User Can Read</label>
                <br>
                <input type="checkbox" class="box" value="@author" v-model="record.usersCanRead"/>
                <span class="text" >&nbsp; @Author </span>
                <br>
                <input type="checkbox" class="box" value="@client" v-model="record.usersCanRead"/>
                <span class="text" >&nbsp; @Client </span>
                <br>
                <input type="checkbox" class="box" value="@responsible" v-model="record.usersCanRead"/>
                <span class="text" >&nbsp; @Responsible </span>
              </div> 
            </div>
          </div>
          <div class="tab-pane fade help " id="help" role="tabpanel" aria-labelledby="help-tab">
            <div class="smart-wrap" style="background: #ffbc003d; margin:10px 0;">
              <div class="input-wrap col-md-12">
                <label for="">Helps</label>
                <textarea name="" id="" cols="7" rows="7" v-model="record.class"></textarea>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </section>
  <section  class="maker body smart-wrap"  v-if="record != null && mode == 'sml' && modeMaker == 'generate'"  >
    <PaperRenderSML :data=" $router.currentRoute.value.query" />
    <PaperRenderSML   :data=" sml" v-for="(sml, index) in customPanel.smlChilds" :key="index"/>
  </section>
  <section class="maker body smart-wrap"  v-if="record != null && post != null && mode == 'post'" >
    <div class="col-md-7" style="margin: 0 0 20px 0;">
      <h2 v-if="post[0].header.name" class="title-g">{{post[0].header.name}}</h2>
      <h2 v-else class="title-g">Layout Name</h2>
      <p  >{{ post[0].header.subject }}</p>

      <div class="smart-wrap col-md-12">
        <div class="input-wrap col-4">
          <label for="">Mode</label>
          <select name="" id="" v-model="modePost">
            <option value="edition">Design</option>
            <option value="preview">Preview</option>
          </select>
        </div>
        <div class="input-wrap col-4" v-if="modePost == 'edition'">
          <label for="">Post Template</label>
          <div class="dropdown show">
            <a  class="dropdown-toggle inputMimo" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 10px; cursor:pointer;" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span v-if="SMLPost" >{{SMLPost.value}} </span> 
              <span v-else> Select a option</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <a class="dropdown-item" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;"  v-on:click="SMLPost = sml; generatePost(SMLPost)"  v-for="(sml, index) in preloadLists['#SML']" :key="index"> 
                {{sml.value}}
              </a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="col-md-5">
      <ui-button style="margin: 0 0 0 10px;" :color="'red'" :position="'right'" :text="'Back'" v-on:click="backMaker()"/>

      <ui-button style="margin: 0 0 0 10px;" :color="'blue'" :position="'right'" :text="'Save'" v-on:click="saveVersions()" />

      <ui-button style="margin: 0 0 0 10px;" :color="'gray'" :position="'right'" :text="'Clear'" v-on:click="saveVersions('clear')"/>
      
      <ui-button style="margin: 0 0 0 10px;" :color="'green'" :position="'right'" :text="'Generate'"  v-on:click="generatePost(SMLPost);" />
    </div>
    
    <div v-if="modePost =='edition'" class="col-12" style="margin: 0 0 20px 0;">
      <div :style="'width:80px;height: 50px; float:left; display:block; background:'+item+';'" v-for="(item, index) in record.palette" :key="index" > </div>
      <ui-button :color="'gray'" :position="'left'" :text="'Color'" style="margin: 0 0 0 10px;" v-if="record.SK" v-on:click="randomColor()" />
      <ui-button :color="'gray'" :position="'left'" :text="'Reverse Colors'" style="margin: 0 0 0 10px;" v-if="record.SK" v-on:click="reversePalette()" />
      <ui-button :color="'gray'" :position="'left'" :text="'Fonts'" style="margin: 0 0 0 10px;" v-if="record.SK" v-on:click="randomFonts()" />
      <ui-button :color="'gray'" :position="'left'" :text="'Logo'" style="margin: 0 0 0 10px;" v-if="record.SK" v-on:click="randomLogo()" />
      <ui-button :color="'gray'" :position="'left'" :text="'Image'" style="margin: 0 0 0 10px;" v-if="record.SK" v-on:click="randomImage()" />
      <ui-button :color="'gray'" :position="'left'" :text="'Texture'" style="margin: 0 0 0 10px;" v-if="record.SK" v-on:click="randomBackground()" />
      <div class="input-wrap" style="display: block; float: left;">
        <div class="check-wrap" style="display: inline;" >
          <div class="envelop" style="display: flex; justify-content: center;" >
            <span class="text" > Texture </span>
            <input type="checkbox" class="box" v-model="viewTextureValue" v-on:click="viewTexture(viewTextureValue)"/>
          </div>
        </div>
      </div>
    </div>
    <div class="list-group-item list-group-item-action " v-for="(version, index) in layoutVersions" :key="index" :style="setStyleVersion(index)">
      <div class="row">
        <div style="float:left; width: calc( 50% - 60px ); " class="input-wrap"> <input type="text" v-model="layoutVersions[index].name"> </div>
        <div style="float:left; width: calc( 50% - 60px ); " class="input-wrap"> 
          <select name="" id="" v-model="layoutVersions[index].format" v-on:change="setFrame(index)">
            <option value="none">None </option>
            <option value="draft">Draft</option>
            <option value="twiterPost">Twiter Post</option>
            <option value="twiterHeader">Twiter Header</option>
            <option value="facebookPost">Facebook Post</option>
            <option value="instagramPost">Instagram Post</option>
            <option value="instagramStory">Instagram Story</option>
            <option value="dribbbleShot">Dribbble Shot</option>
            <option value="linkedinCover">Linkedin Cover</option>
          </select>
        </div>
        <div class="options">
          <div class="col edit" style="float:rigth; cursor: pointer; " v-on:click="actionVersion('view', index)" >
            <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.55917 15.0159H5.68547C5.27612 15.016 4.87664 14.8901 4.54129 14.6554C4.20593 14.4206 3.95094 14.0884 3.81094 13.7037L0.0618791 3.39209C-0.0707341 3.02816 0.0200011 2.62036 0.293204 2.34617L0.585352 2.05403L6.98867 8.45717C6.98767 8.4831 6.98069 8.50902 6.98069 8.53494C6.98069 8.83074 7.06841 9.1199 7.23275 9.36585C7.3971 9.6118 7.63068 9.80349 7.90398 9.91669C8.17727 10.0299 8.47799 10.0595 8.76812 10.0018C9.05824 9.94409 9.32474 9.80165 9.53391 9.59249C9.74307 9.38332 9.88552 9.11683 9.94323 8.82672C10.0009 8.5366 9.97132 8.23589 9.85812 7.9626C9.74492 7.68932 9.55322 7.45574 9.30726 7.2914C9.06131 7.12706 8.77214 7.03935 8.47633 7.03935C8.45041 7.03935 8.42448 7.04633 8.39856 7.04732L1.99524 0.644182L2.28739 0.352044C2.42145 0.217648 2.59119 0.124444 2.77654 0.0834522C2.96189 0.0424605 3.1551 0.0553948 3.33334 0.120725L13.6453 3.87068C14.0299 4.01068 14.3622 4.26566 14.5969 4.601C14.8317 4.93635 14.9575 5.33581 14.9574 5.74516V9.61875L16.9506 11.6109L11.5524 17.009L9.55917 15.0159V15.0159ZM17.8879 12.9599L12.9024 17.9452L15.0163 20.06L20.0017 15.0747L17.8879 12.9599Z" fill="white"/>
            </svg>
          </div>
          <div class="col delete" style="float:rigth; cursor: pointer;" v-on:click="actionVersion('delete', index)" >
            <svg width="78" height="100" viewBox="0 0 78 100" fill="none" >
              <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#000" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div v-if="modePost =='edition'" class="col-md-8 constructor">
      <paper-constructor  :listDrop="customLayout" :palette="record.palette" :styleDragg="setSetyleBoddy(record.bodyStyle)" :idioms="record.idioms" :language="customPanel.language" />
    </div>
    <div v-if="modePost =='preview'" class="col-md-12 constructor">
      <paper-layout  :style="setSetyleBoddy(record.bodyStyle) + 'margin:0 auto; padding:10px; border:2px dashed #ccc; width:1020px;'" :idioms="['en']" :language="customPanel.language" :layout="customLayout" :models="{}" :palette="record.palette" :paperPermissions="{usersCanEdit:[], usersCanRead:[],groupsCanEdit:[],groupsCanRead:[]}"/>
    </div>
    <div v-if="modePost =='edition'" class="col-md-4 custom" style="z-index: 0; padding:0 0 0 20px;">
      <div class="customPanel middleShadow" id="stickyPanel">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link" id="layers-tab" data-toggle="tab" href="#layers" role="tab" aria-controls="layers" aria-selected="false">Layers</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="info-tab" data-toggle="tab" href="#info" role="tab" aria-controls="info" aria-selected="false">Info</a>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade layers show active" id="layers" role="tabpanel" aria-labelledby="layers-tab">
            {{record}}
            <paper-layers :src="webSite" v-if="customLayout != null" :data="customLayout" />
          </div>
          <div class="tab-pane fade info " id="info" role="tabpanel" aria-labelledby="info-tab">
            <div class="smart-wrap" style="background: #ffbc003d; margin:10px 0;">
              <div class="input-wrap col-md-12">
                <label for="">Css Fonts</label>
                <textarea name="" id="" cols="7" rows="7" v-model="record.fontScript"></textarea>
              </div>
              <div class="input-wrap col-md-12" v-for="(font, index) in record.fonts" :key="index">
                <label for="">Font {{index}}</label>
                <input type="text" v-model="record.fonts[index]"> 
              </div>
            </div> 
            <div class="smart-wrap">
              <div class="input-wrap col-md-12">
                <label for="">Body Style</label>
                <textarea name="" id="" cols="30" rows="10" v-model="record.bodyStyle"></textarea>
              </div>
              <div class="input-wrap col-md-12">
                <h6 for="" >Language</h6>
                <ul class="list-group list-languages" >
                  <li :class="{ 'list-group-item ':true, 'active': item == customPanel.language , } " v-for="(item, index) in record.idioms" :key="index" style="padding:10px 0px 10px 10px; display:block;" > 
                    <select name="" id="" v-model="record.idioms[index]" class="col-10" style="display:block; float:left; width:calc(100% - 100px);">
                      <option v-show="!record.idioms.includes(vindex)" :value="vindex" v-for="(idiom, vindex) in srcIdioms" :key="vindex">{{idiom}}</option>
                    </select> 
                    <div class="options" style="display:block; float:left; width:100px; " >
                      <ui-action-button style="margin:0 0 0 10px; cursor:pointer;" action='view' v-on:click="customPanel.language = item"/>
                      <ui-action-button style="margin:0 0 0 10px; cursor:pointer;" action='delete' v-on:click="record.idioms.splice(index, 1)"/>
                    </div>
                  </li> 
                  <li class="list-group-item add-table" style="text-align:center; cursor:pointer;" v-on:click="record.idioms.push('')" > 
                    Add New 
                    <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="#5a5a5a" stroke-width="2"><path stroke-linecap="round" d="M12 8v4m0 0v4m0-4h4m-4 0H8"/><circle cx="12" cy="12" r="10"/></g></svg>
                  </li> 
                </ul>
              </div>
              
              <div class="input-wrap col-md-6">
                <label for="">Main Color</label>
                <input type="text"  v-model="record.palette[0]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[0]+';'"> </div>
              <div class="input-wrap col-md-6">
                <label for="">Secondary  Color</label>
                <input type="text"  v-model="record.palette[1]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[1]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">Font Primary Color</label>
                <input type="text"  v-model="record.palette[2]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[2]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">Font Secondary Color</label>
                <input type="text"  v-model="record.palette[3]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[3]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">Acent Color</label>
                <input type="text"  v-model="record.palette[4]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[4]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">Black Color</label>
                <input type="text"  v-model="record.palette[5]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[5]+';'"></div>
              <div class="input-wrap col-md-6">
                <label for="">White Color</label>
                <input type="text"  v-model="record.palette[6]">
              </div>
              <div class="input-wrap col-md-6" :style="'background:'+record.palette[6]+';'"></div>
            </div>
            <ui-button text="Apply Colors" color="blue" position="center"  style="margin:10px auto ;padding: 7px 20px; width:200px;" v-on:click="setWebSite(record)"/>
            <div class="elemet-wrap row col-md-12"  style="background: #ffbc003d;">
              <paper-displayer :value="post"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </section>
  <section class="maker footer">
    <ui-modal name="custom" type="custom"  v-if="record != null">
      <template v-if="customItem != null" #content>
        <div class="panel smart-wrap">
          <div class="element-wrap col-md-12" style="margin:0;">
            <h4>{{customItem.attributeName}}</h4>
            <a href="http://helpsmd.s3.amazonaws.com/customitem.html" target="_blank" rel="noopener noreferrer"> Click to help</a>
            <p>{{customItem.id}}</p>
          </div>
          <div class="input-wrap col-md-2" style="margin:0;">
            <label for="">Version</label>
            <input type="number" v-model="customItem.version" >
          </div>
          <div class="input-wrap col-md-8" style="margin:0;">
            <label for="">Attribute Name</label>
            <div class="dropdown show labelOptions">
              <a href="https://help.smartdash/customItem.attributeName.html" target="_blank">
                <svg   width="12" height="12" x="0" y="0" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                  <path d="M248.158,343.22c-14.639,0-26.491,12.2-26.491,26.84c0,14.291,11.503,26.84,26.491,26.84     c14.988,0,26.84-12.548,26.84-26.84C274.998,355.42,262.799,343.22,248.158,343.22z" fill="#0081cf" data-original="#000000" style="" class="" />
                  <path d="M252.69,140.002c-47.057,0-68.668,27.885-68.668,46.708c0,13.595,11.502,19.869,20.914,19.869     c18.822,0,11.154-26.84,46.708-26.84c17.429,0,31.372,7.669,31.372,23.703c0,18.824-19.52,29.629-31.023,39.389     c-10.108,8.714-23.354,23.006-23.354,52.983c0,18.125,4.879,23.354,19.171,23.354c17.08,0,20.565-7.668,20.565-14.291     c0-18.126,0.35-28.583,19.521-43.571c9.411-7.32,39.04-31.023,39.04-63.789S297.307,140.002,252.69,140.002z" fill="#0081cf" data-original="#000000" style="" class="" />
                  <path d="M256,0C114.516,0,0,114.497,0,256v236c0,11.046,8.954,20,20,20h236c141.483,0,256-114.497,256-256     C512,114.516,397.503,0,256,0z M256,472H40V256c0-119.377,96.607-216,216-216c119.377,0,216,96.607,216,216     C472,375.377,375.393,472,256,472z" fill="#0081cf" data-original="#000000" style="" class="" />
                </svg>
              </a>
            </div>
            <input type="text" v-model="customItem.attributeName" >
          </div>
          <div class="input-wrap col-md-2 " style="margin:0;">
            <div class="check-wrap">
              <label for="">Print</label>
              <div class="envelop">
                <input type="checkbox" name="" id="" v-model="customItem.print">
              </div>
            </div>
          </div>
          <!-- Styles -->
          <div class="elemet-wrap row col-md-12"  style="background: #ffbc003d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px"> Styles </h6>
            <div class="input-wrap col-md-12" style="padding: 10px 15px"> 
              <label > Class </label>
              <input type="text" v-model="customItem.class">
            </div> 
            <div class="customPanelOption col-md-12"> 
              <a class="drop" data-toggle="collapse" href="#collapse-style-font" role="button" aria-expanded="false" aria-controls="collapse-style">
                Font 
                <svg style="float: right; margin: 0 10px 0 0;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M30 12L16 24L2 12"/></svg>
              </a>
              <div class="collapse" id="collapse-style-font">
                <div class="smart-wrap" style="border-bottom: 1px solid #ccc;">
                  <div class="input-wrap col-6"> 
                    <label >Color </label>
                    <div class="dropdown show">
                      <a  class="dropdown-toggle inputMimo" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 10px; cursor:pointer;" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="typeof customItem.style.font.color == 'number'" :style="' width: 20px; height: 20px;  background:'+record.palette[customItem.style.font.color]+';'"></span> &nbsp;
                        <span v-if="typeof customItem.style.font.color == 'number'" > Color {{customItem.style.font.color}} </span> 
                        <span v-else> Select a option</span>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;" v-on:click="customItem.style.font.color = index" v-for="(color, index) in record.palette" :key="index"> 
                          <span :style="' width: 20px; height: 20px;  background:'+record.palette[index]+';'"></span>
                          &nbsp; Color {{index}}
                        </a>
                        <a class="dropdown-item" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;" v-on:click="customItem.style.font.color = ''" > 
                          <span :style="' width: 20px; height: 20px;  '"></span>
                          &nbsp; None
                        </a>
                      </div>
                    </div>
                  </div> 
                  <div class="input-wrap col-6"> 
                    <label > Font Family</label>
                    <div class="dropdown show">
                      <a  class="dropdown-toggle inputMimo" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 10px; cursor:pointer;" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="JSON.stringify(customItem.style.font.font)" >
                          <span :style="'font-family:'+ record.fonts[customItem.style.font.font]+';'">{{ customItem.style.font.font}} ( {{ record.fonts[customItem.style.font.font] }} )</span>
                        </span> 
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" :style="'display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;font-family:' +font+';'" v-on:click="customItem.style.font.font = index" v-for="(font, index) in record.fonts" :key="index"> 
                          {{index}} &nbsp; ({{font}})
                        </a>
                        <a class="dropdown-item" :style="'display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;'" v-on:click="customItem.style.font.font = ''" > 
                          None
                        </a>
                      </div>
                    </div>
                  </div> 
                  <div class="input-wrap col-2">  
                    <label > Size </label>
                    <input type="text" name="" id="" min="5" v-model="customItem.style.font.size[0]" >
                  </div> 
                  <div class="input-wrap col-2"> 
                    <label > 992 </label>
                    <input type="text" name="" id="" min="5" v-model="customItem.style.font.size[1]" >
                  </div> 
                  <div class="input-wrap col-2"> 
                    <label > 767 </label>
                    <input type="text" name="" id="" min="5" v-model="customItem.style.font.size[2]" >
                  </div> 
                  <div class="input-wrap col-2"> 
                    <label > 520 </label>
                    <input type="text" name="" id="" min="5" v-model="customItem.style.font.size[3]" >
                  </div> 
                  <div class="input-wrap col-3"> 
                    <label > Weight </label>
                    <input type="number" v-model="customItem.style.font.weight">
                  </div> 
                </div>
              </div>
            </div> 
            <div class="customPanelOption col-md-12"> 
              <a class="drop" data-toggle="collapse" href="#collapse-style-box" role="button" aria-expanded="false" aria-controls="collapse-style">
                Box Model 
                <svg style="float: right; margin: 0 10px 0 0;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M30 12L16 24L2 12"/></svg>
              </a>
              <div class="collapse" id="collapse-style-box">
                <div class="smart-wrap" style="border-bottom: 1px solid #ccc;">
                  <div class="input-wrap col-12"> 
                    <label > BG Color </label>
                    <div class="dropdown show">
                      <a  class="dropdown-toggle inputMimo" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 10px; cursor:pointer;" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="typeof customItem.style.bgc == 'number'" :style="' width: 20px; height: 20px;  background:'+record.palette[customItem.style.bgc]+';'"></span> &nbsp;
                        <span v-if="typeof customItem.style.bgc == 'number'" >Color {{customItem.style.bgc}} </span> 
                        <span v-else> Select a option</span>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;" v-on:click="customItem.style.bgc = index" v-for="(color, index) in record.palette" :key="index"> 
                          <span :style="' width: 20px; height: 20px;  background:'+record.palette[index]+';'"></span>
                          &nbsp; Color {{index}}
                        </a>
                        <a class="dropdown-item" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;" v-on:click="customItem.style.bgc = ''" > 
                          <span :style="' width: 20px; height: 20px;  '"></span>
                          &nbsp; None
                        </a>
                      </div>
                    </div>
                  </div> 
                  <div class="input-wrap col-6" style="padding: 5px 0;"> 
                    <label > Border Color </label>
                    <div class="dropdown show">
                      <a  class="dropdown-toggle inputMimo" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 10px; cursor:pointer;" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span v-if="customPanel.palette[customItem.style.border.color]" :style="' width: 20px; height: 20px;  background:'+record.palette[customItem.style.border.color]+';'"></span> &nbsp;
                        <span v-if="customPanel.palette[customItem.style.border.color]" >{{customPanel.palette[customItem.style.border.color]}} </span> 
                        <span v-else> Select a option</span>
                      </a>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;" v-on:click="customItem.style.border.color = index" v-for="(color, index) in customPanel.palette" :key="index"> 
                          <span :style="' width: 20px; height: 20px;  background:'+record.palette[index]+';'"></span>
                          &nbsp; {{color}}
                        </a>
                        <a class="dropdown-item" style="display: flex; flex-wrap: nowrap; align-items: center; padding: 5px 20px; cursor:pointer;" v-on:click="customItem.style.border.color = ''" > 
                          <span :style="' width: 20px; height: 20px;  '"></span>
                          &nbsp; None
                        </a>
                      </div>
                    </div>
                  </div> 
                  <div class="input-wrap col-3" style="padding: 5px 0;" > 
                    <label > Weight </label>
                    <input type="text" v-model="customItem.style.border.weight">
                  </div> 
                  <div class="input-wrap col-3" style="padding: 5px 0;"> 
                    <label > Type </label>
                    <input type="text" v-model="customItem.style.border.type">
                  </div> 
                  <div class="input-wrap col-6"> 
                    <label > Padding </label>
                    <input type="text" name="" id="" v-model="customItem.style.padding">
                  </div> 
                  <div class="input-wrap col-6"> 
                    <label > Margin </label>
                    <input type="text" name="" id="" v-model="customItem.style.margin">
                  </div> 
                </div>
              </div>
            </div> 
            <div class="customPanelOption col-md-12"> 
              <a class="drop" data-toggle="collapse" href="#collapse-style-size" role="button" aria-expanded="false" aria-controls="collapse-style">
                Size 
                <svg style="float: right; margin: 0 10px 0 0;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M30 12L16 24L2 12"/></svg>
              </a>
              <div class="collapse" id="collapse-style-size">
                <div class="smart-wrap" style="border-bottom: 1px solid #ccc;">
                  <div class="smart-wrap" style="background: #ffe396; padding: 10px;">
                    <p style="display: block;width: 100%;">Width Wrap</p>
                    <div class="input-wrap col-6"> 
                      <label > Desk (1200px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.width[0]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Laptop(992px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.width[1]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Tablet (767px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.width[2]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Phone (520px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.width[3]" >
                    </div> 
                  </div>
                  <div class="smart-wrap" style="padding: 10px;">
                    <p style="display: block;width: 100%;">Width Component</p>
                    <div class="input-wrap col-6"> 
                      <label >  Desk (1200px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.widthC[0]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Laptop(992px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.widthC[1]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Tablet (767px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.widthC[2]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Phone (520px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.widthC[3]" >
                    </div> 
                  </div>
                  <div class="smart-wrap" style="background: #ffe396; padding: 10px;">
                    <p style="display: block;width: 100%;">Height Wrap</p>
                    <div class="input-wrap col-6"> 
                      <label >  Desk (1200px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.height[0]">
                    </div>  
                    <div class="input-wrap col-6"> 
                      <label > Laptop(992px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.height[1]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Tablet (767px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.height[2]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Phone (520px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.height[3]" >
                    </div> 
                  </div>
                  <div class="smart-wrap" style=" padding: 10px;">
                    <p style="display: block;width: 100%;">Height Component</p>
                    <div class="input-wrap col-6"> 
                      <label > Desk (1200px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.heightC[0]">
                    </div>  
                    <div class="input-wrap col-6"> 
                      <label > Laptop(992px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.heightC[1]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Tablet (767px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.heightC[2]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > Phone (520px) </label>
                      <input type="text" name="" id="" v-model="customItem.style.heightC[3]" >
                    </div> 
                    <div class="input-wrap col-6"> 
                      <label > transform </label>
                      <input type="number" name="" id="" v-model="customItem.style.transform">
                    </div> 
                  </div>
                  
                </div>
              </div>
            </div> 
            <div class="customPanelOption col-md-12"> 
              <a class="drop" data-toggle="collapse" href="#collapse-style-advanced" role="button" aria-expanded="false" aria-controls="collapse-style">
                Advanced 
                <svg style="float: right; margin: 0 10px 0 0;" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M30 12L16 24L2 12"/></svg>
              </a>
              <div class="collapse" id="collapse-style-advanced">
                <div  v-if="customItem.metaType != 'metaInput'" class="smart-wrap" style="border-bottom: 1px solid #ccc;">
                  <div class="input-wrap col-md-12"> 
                    <label > Style Wrap</label>
                    <textarea rows="8" v-model="customItem.styleWrap"></textarea>
                  </div> 
                  <div class="input-wrap col-md-12"> 
                    <label > Style Component</label>
                    <textarea rows="8" v-model="customItem.styleComponent"></textarea>
                  </div> 
                </div>
                <div  v-else class="smart-wrap" style="border-bottom: 1px solid #ccc;">
                  <div class="input-wrap col-md-12"> 
                    <label > Style Wrap</label>
                    <textarea rows="8" v-model="customItem.styleWrap"></textarea>
                  </div> 
                  <div class="input-wrap col-md-12"> 
                    <label > Style Label</label>
                    <textarea rows="8" v-model="customItem.styleLabel"></textarea>
                  </div> 
                  <div class="input-wrap col-md-12"> 
                    <label > Style Input</label>
                    <textarea rows="8" v-model="customItem.styleInput"></textarea>
                  </div> 
                </div>
              </div>
            </div> 
          </div>
          <!-- Element -->
          <div v-if="customItem.metaType == 'metaElement'" class="elemet-wrap row col-md-12"  style="background: #B0F5013d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px">Element <strong style="padding: 0 10px;" >{{customItem.elementType}}</strong> </h6>
            <div v-if="customItem.elementType == 'Div'" class="input-wrap col-md-12"> 
              <label > Div Type</label>
              <select name="" id="" v-model="customItem.divType">
                <option value="standard">Standard</option>
                <option value="step">Step</option>
                <option value="choice">Choice</option>
              </select>
            </div> 
            
            <div v-if="customItem.elementType == 'menu'" class="input-wrap col-md-12"> 
              <label > Menu Icon </label>
              <input type="text" v-model="customItem.url"  />
            </div> 
            <div class="input-wrap col-md-12" v-if="customItem.elementType == 'smartButton'">
              <label > Type Button </label>
              <select name="" id="" v-model="customItem.buttonType">
                <option value="link">Front End</option>
                <option value="step">Step</option>
                <option value="queryButton ">Query Button </option>
                <option value="mutationButton">Mutation Button</option>
                <option value="appointment">Appointment</option>
                <option value="quizAction">Quiz Action</option>
                <option value="form">Form</option>
                <option value="contactForm">Contact Form</option>
              </select>
            </div>
            <div class="input-wrap col-md-12" v-if="customItem.buttonType == 'quizAction'"> 
              <label >Query Parameters (GRAPHQL, Preload List) &nbsp;</label>
              <textarea id="custom-metaJSON" rows="5" :value="JSON.stringify(customItem.metaJSON) " v-on:keyup="validationInputJSON('custom-metaJSON', 'metaJSON')"></textarea>
            </div> 
            <div v-if="customItem.elementType == 'smartButton' &&  (customItem.buttonType && customItem.buttonType == 'step')" class="input-wrap col-md-12"> 
              <label > BackEnd Step Operation Name</label>
              <input type="text" v-model="customItem.stepType">
              <!--select-- name="" id="" v-model="customItem.stepType">
                <option value="choice">Choice</option>
                <option value="payment">Payment</option>
                <option value="form">Contact Form</option>
                <option value="setPass">Set Password</option>
                <option value="confirmEmail">Confirm Email</option>
                <option value="display">Display</option>
              </!--select-->
            </div> 
            <div class="input-wrap col-md-12" v-if="customItem.elementType == 'Title'">
              <label > Type Title </label>
              <select name="" id="" v-model="customItem.titleType">
                <option value="h1">H1</option>
                <option value="h2">H2</option>
                <option value="h3">H3</option>
                <option value="h4">H4</option>
                <option value="h5">H5</option>
                <option value="h6">H6</option>
              </select>
            </div>
            <div class="input-wrap col-md-12" v-if="customItem.elementType == 'link'">
              <label > Link Type  </label>
              <select name="" id="" v-model="customItem.linkType">
                <option value="standar">Standar</option>
                <option value="phone">Phone</option>
                <option value="email">Email</option>
                <option value="redes">Redes</option>
              </select>
            </div>
            <div v-show="customItem.elementType == 'Title' ||customItem.elementType == 'metaModule' || customItem.elementType == 'Text' || customItem.elementType == 'smartButton'" class="input-wrap col-md-12" v-for="(item, index) in record.idioms" :key="index" >
              <label > Text {{item.toUpperCase()}}</label>
              <textarea rows="5" v-model="customItem.text[item]" ></textarea>
            </div>
            <div v-if="customItem.elementType == 'video'" class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Plays Inline</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.playsinline">
                </div>
              </div>
            </div>
            <div v-if="customItem.elementType == 'video'" class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Autoplay</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.autoplay">
                </div>
              </div>
            </div>
            <div v-if="customItem.elementType == 'video'" class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Muted</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.muted">
                </div>
              </div>
            </div>
            <div v-if="customItem.elementType == 'video' " class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Loop</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.loop">
                </div>
              </div>
            </div>
            <div v-if="customItem.elementType == 'video' || customItem.elementType == 'metaModule' || customItem.elementType.toLowerCase() == 'img' || customItem.elementType == 'link' || customItem.elementType == 'iframe'" class="input-wrap col-md-10"> 
              <label > URL / Image </label>
              <input type="text" v-model="customItem.url"  />
            </div> 
            <div v-if="customItem.elementType == 'video' || customItem.elementType == 'metaModule' || customItem.elementType.toLowerCase() == 'img' || customItem.elementType == 'link'"  class="input-wrap col-md-2"> 
              <label > Target (Change) </label>
              <input type="text" v-model="customItem.target"  />
            </div>
            <div v-if="customItem.elementType.toLowerCase() == 'img'" class="input-wrap col-md-3">
              <div class="check-wrap">
                <label for="">Custom</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.customImg">
                </div>
              </div>
            </div>
            <div v-if="customItem.elementType.toLowerCase() == 'img' && customItem.customImg == true" class="input-wrap col-md-3">
              <label > No. Img </label>
              <input type="number" v-model="customItem.noImg"  />
            </div>
            <div v-if="customItem.elementType.toLowerCase() == 'img'" class="input-wrap col-md-3">
              <div class="check-wrap">
                <label for="">Show in modal</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.showModal">
                </div>
              </div>
            </div>
            <div v-if="customItem.elementType.toLowerCase() == 'img' && customItem.showModal == true" class="input-wrap col-md-12" >
              <label > Size MOdal  </label>
              <select name="" id="" v-model="customItem.sizeModal">
                <option value="large">Large</option>
                <option value="middle">Middle</option>
                <option value="small">Small</option>
              </select>
            </div>
            <div class="input-wrap col-md-12" v-if="condition"> 
              <label >Query Parameters (GRAPHQL, Preload List, Button) &nbsp;</label>
              <div class="dropdown show labelOptions">
                <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg   width="12" height="12" x="0" y="0" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                    <path d="M248.158,343.22c-14.639,0-26.491,12.2-26.491,26.84c0,14.291,11.503,26.84,26.491,26.84     c14.988,0,26.84-12.548,26.84-26.84C274.998,355.42,262.799,343.22,248.158,343.22z" fill="#0081cf" data-original="#000000" style="" class="" />
                    <path d="M252.69,140.002c-47.057,0-68.668,27.885-68.668,46.708c0,13.595,11.502,19.869,20.914,19.869     c18.822,0,11.154-26.84,46.708-26.84c17.429,0,31.372,7.669,31.372,23.703c0,18.824-19.52,29.629-31.023,39.389     c-10.108,8.714-23.354,23.006-23.354,52.983c0,18.125,4.879,23.354,19.171,23.354c17.08,0,20.565-7.668,20.565-14.291     c0-18.126,0.35-28.583,19.521-43.571c9.411-7.32,39.04-31.023,39.04-63.789S297.307,140.002,252.69,140.002z" fill="#0081cf" data-original="#000000" style="" class="" />
                    <path d="M256,0C114.516,0,0,114.497,0,256v236c0,11.046,8.954,20,20,20h236c141.483,0,256-114.497,256-256     C512,114.516,397.503,0,256,0z M256,472H40V256c0-119.377,96.607-216,216-216c119.377,0,216,96.607,216,216     C472,375.377,375.393,472,256,472z" fill="#0081cf" data-original="#000000" style="" class="" />
                  </svg>
                </a>
                <div class="dropdown-menu options" aria-labelledby="dropdownMenuLink" style="padding:20px;">
                  <paper-displayer :value="{entityType:'ORGANIZATION/SYSTEM',shortEntity:'#',fieldID:' att ',fieldValue:' att '}" />
                </div>
              </div>
              <div class="dropdown show labelOptions">
              <a href="https://help.smartdash/customItem.metaJSON.html" target="_blank">
                <svg   width="12" height="12" x="0" y="0" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                  <path d="M248.158,343.22c-14.639,0-26.491,12.2-26.491,26.84c0,14.291,11.503,26.84,26.491,26.84     c14.988,0,26.84-12.548,26.84-26.84C274.998,355.42,262.799,343.22,248.158,343.22z" fill="#0081cf" data-original="#000000" style="" class="" />
                  <path d="M252.69,140.002c-47.057,0-68.668,27.885-68.668,46.708c0,13.595,11.502,19.869,20.914,19.869     c18.822,0,11.154-26.84,46.708-26.84c17.429,0,31.372,7.669,31.372,23.703c0,18.824-19.52,29.629-31.023,39.389     c-10.108,8.714-23.354,23.006-23.354,52.983c0,18.125,4.879,23.354,19.171,23.354c17.08,0,20.565-7.668,20.565-14.291     c0-18.126,0.35-28.583,19.521-43.571c9.411-7.32,39.04-31.023,39.04-63.789S297.307,140.002,252.69,140.002z" fill="#0081cf" data-original="#000000" style="" class="" />
                  <path d="M256,0C114.516,0,0,114.497,0,256v236c0,11.046,8.954,20,20,20h236c141.483,0,256-114.497,256-256     C512,114.516,397.503,0,256,0z M256,472H40V256c0-119.377,96.607-216,216-216c119.377,0,216,96.607,216,216     C472,375.377,375.393,472,256,472z" fill="#0081cf" data-original="#000000" style="" class="" />
                </svg>
              </a>
            </div>
              <textarea id="custom-metaJSON" rows="5" :value="JSON.stringify(customItem.metaJSON) " v-on:keyup="validationInputJSON('custom-metaJSON', 'metaJSON')"></textarea>
            </div> 
          </div>
          <!-- Input -->
          <div v-if="customItem.metaType == 'metaInput'" class="elemet-wrap row col-md-12"  style="background: #F5E0013d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px"> Input </h6>
            <div  class="input-wrap col-md-12"> 
              <label > Input Type </label>
              <select v-model="customItem.inputType">
                <option value="Boolean" >Boolean</option>
                <option value="CheckBox" >CheckBox</option>
                <option value="cognitoGroups" >Cognito Groups</option>
                <option value="Color" >Color</option>
                <option value="ColorLess" >Color Less</option>
                <option value="Counter" >Counter</option>
                <option value="Currency" >Currency</option> 
                <option value="Date" >Date</option>
                <option value="Editor" >Editor (discontinued)</option>
                <option value="Email" >Email</option>
                <option value="File" >File</option>
                <option value="GRAPHQL" >GRAPHQL</option>
                <option value="createdBy" >Created By</option>
                <option value="HTML" >HTML</option>
                <option value="JSON" >JSON</option>
                <option value="Number" >Number</option>
                <option value="Phone" >Phone</option>
                <option value="preloadList" >Preloaded List</option>
                <option value="quote" >Quote</option>
                <option value="RadioButton" >RadioButton</option>
                <option value="Range" >Range</option>
                <option value="Select" >Select</option>
                <option value="Text" >Text</option>
                <option value="TextArea" >Text Area</option>
                <option value="ZipCode" >ZipCode</option>
              </select>
            </div> 
            <div v-if="customItem.inputType == 'Text'" class="input-wrap col-md-6"> 
              <label > Format Type </label>
              <select v-model="customItem.format">
                <option value="numeric">Numeric</option>
                <option value="string">String</option>
                <option value="currency">Currency</option>
              </select>
            </div>
            <div v-if="customItem.inputType == 'GRAPHQL'" class="input-wrap col-md-2"> 
              <label > Version </label>
              <input type="number" min="1" max="2"  v-model="customItem.gqlVersion">
            </div>
            <div v-if="customItem.inputType == 'GRAPHQL'" class="input-wrap col-md-2"> 
              <div class="check-wrap">
                <label > Allow Create </label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.allowCreate">
                </div>
              </div>
            </div>
            <div v-if="customItem.inputType == 'GRAPHQL' " class="input-wrap col-md-2"> 
              <div class="check-wrap">
                <label > Allow Complete </label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.allowComplete">
                </div>
              </div>
            </div>
            <div  class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label > Computed </label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.computed">
                </div>
              </div>
            </div>
            <div class="col-12 smart-wrap">
              <div class="input-wrap col-md-3"> 
                <div class="check-wrap">
                  <label >Compare To</label>
                  <div class="envelop">
                    <input type="checkbox" name="" id="" v-model="customItem.compareTo">
                  </div>
                </div>
              </div>
              <div v-if=" (customItem.compareTo && customItem.compareTo == true) " class="input-wrap col-md-3"> 
                <label > Compare Type </label>
                <select name="" id=""  v-model="customItem.compareType">
                  <option value="=">=</option>
                  <option value="<">&gt;</option>
                  <option value=">">&lt;</option>
                </select>
              </div>
              <div v-if=" (customItem.computed && customItem.computed == true) || (customItem.compareTo && customItem.compareTo == true)  " class="input-wrap col-md-6"> 
                <label > Target Bind </label>
                <input type="text" v-model="customItem.targetBind"  />
              </div>
            </div>
            <div  class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label > On Change </label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.onChange">
                </div>
              </div>
            </div>
            <div v-if=" (customItem.onChange && customItem.onChange == true) " class="input-wrap col-md-4"> 
              <label > On Change Condition </label>
              <select name="" id=""  v-model="customItem.onChangeCondition">
                <option value="=">=</option>
                <option value="<">&gt;</option>
                <option value=">">&lt;</option>
              </select>
            </div>
            <div v-if=" (customItem.onChange && customItem.onChange == true) " class="input-wrap col-md-5"> 
              <label > Value </label>
              <input type="text" v-model="customItem.onChangeCompareTo"  />
            </div>
            <div v-if=" customItem.onChange && customItem.onChange == true" class="input-wrap col-md-6"> 
              <label > Target Bind </label>
              <input type="text" v-model="customItem.targetBind"  />
            </div>
            <div v-if=" customItem.onChange && customItem.onChange == true" class="input-wrap col-md-6"> 
              <label > Target New Value </label>
              <input type="text" v-model="customItem.onChangeResult"  />
            </div>
            <div class="input-wrap col-md-12"> 
              <label > Input Label </label>
              <input type="text" v-model="customItem.label"  />
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Is Repeatable</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.isRepeatable">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Read Only</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.readOnly">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Client Input</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.smartInput">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Is Required</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.required">
                </div>
              </div>
            </div> 
            <div  class="input-wrap col-md-12"> 
              <label > Input Additionl Description </label>
              <textarea rows="5" v-model="customItem.description"></textarea>
            </div> 
            <div class="input-wrap col-md-12"> 
              <label > Input Help </label>
              <textarea name="" id="" cols="30" rows="5" v-model="customItem.help"></textarea>
            </div>
            <div class="input-wrap col-md-12"> 
              <label for="">Options List (Select, Checks, Radio, etc)</label>\
              <textarea id="custom-valueOptions" rows="5" :value="JSON.stringify(customItem.valueOptions) " v-on:keyup="validationInputJSON('custom-valueOptions', 'valueOptions')"></textarea>
            </div>
            <div class="input-wrap col-md-12" > 
              <label > File Security Level </label>
              <select v-model="customItem.securityLevel">
                <option value="public">Public</option>
                <option value="protected">Protected</option>
                <option value="private">Private</option>
                <option value="open">None</option>
              </select>
            </div> 
            <div class="input-wrap col-md-12"> 
              <label >Query Parameters (GRAPHQL, Preload List) &nbsp;</label>
              <div class="dropdown show labelOptions">
                <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg   width="12" height="12" x="0" y="0" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                    <path d="M248.158,343.22c-14.639,0-26.491,12.2-26.491,26.84c0,14.291,11.503,26.84,26.491,26.84     c14.988,0,26.84-12.548,26.84-26.84C274.998,355.42,262.799,343.22,248.158,343.22z" fill="#0081cf" data-original="#000000" style="" class="" />
                    <path d="M252.69,140.002c-47.057,0-68.668,27.885-68.668,46.708c0,13.595,11.502,19.869,20.914,19.869     c18.822,0,11.154-26.84,46.708-26.84c17.429,0,31.372,7.669,31.372,23.703c0,18.824-19.52,29.629-31.023,39.389     c-10.108,8.714-23.354,23.006-23.354,52.983c0,18.125,4.879,23.354,19.171,23.354c17.08,0,20.565-7.668,20.565-14.291     c0-18.126,0.35-28.583,19.521-43.571c9.411-7.32,39.04-31.023,39.04-63.789S297.307,140.002,252.69,140.002z" fill="#0081cf" data-original="#000000" style="" class="" />
                    <path d="M256,0C114.516,0,0,114.497,0,256v236c0,11.046,8.954,20,20,20h236c141.483,0,256-114.497,256-256     C512,114.516,397.503,0,256,0z M256,472H40V256c0-119.377,96.607-216,216-216c119.377,0,216,96.607,216,216     C472,375.377,375.393,472,256,472z" fill="#0081cf" data-original="#000000" style="" class="" />
                  </svg>
                </a>
                <div class="dropdown-menu options" aria-labelledby="dropdownMenuLink" style="padding:20px;">
                  <paper-displayer :value="{entityType:'ORGANIZATION/SYSTEM',shortEntity:'#',fieldID:' att ',fieldValue:' att '}" />
                </div>
              </div>
              <textarea id="custom-metaJSON" rows="5" :value="JSON.stringify(customItem.metaJSON) " v-on:keyup="validationInputJSON('custom-metaJSON', 'metaJSON')"></textarea>
            </div> 
          </div>
          <!-- Layout -->
          <div v-if="customItem.metaType == 'metaLayout'" class="elemet-wrap row col-md-12"  style="background: #00F5C93d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px">Layout</h6>
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Binded-layout</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.web">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Is Repeatable</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.isRepeatable">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3" v-if="customItem.isRepeatable == true"> 
              <label > Table Version </label>
              <input type="number" v-model="customItem.tableVersion"  />
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Read Only</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.readOnly">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-3"> 
              <div class="check-wrap">
                <label for="">Client Input</label>
                <div class="envelop">
                  <input type="checkbox" name="" id="" v-model="customItem.smartInput">
                </div>
              </div>
            </div> 
            <div class="input-wrap col-md-12"> 
              <label > Layout Label </label>
              <input type="text" v-model="customItem.label"  />
            </div> 
            <div class="input-wrap col-md-12"> 
              <label >Rules &nbsp;</label>
              <div class="dropdown show labelOptions">
                <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg   width="12" height="12" x="0" y="0" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                    <path d="M248.158,343.22c-14.639,0-26.491,12.2-26.491,26.84c0,14.291,11.503,26.84,26.491,26.84     c14.988,0,26.84-12.548,26.84-26.84C274.998,355.42,262.799,343.22,248.158,343.22z" fill="#0081cf" data-original="#000000" style="" class="" />
                    <path d="M252.69,140.002c-47.057,0-68.668,27.885-68.668,46.708c0,13.595,11.502,19.869,20.914,19.869     c18.822,0,11.154-26.84,46.708-26.84c17.429,0,31.372,7.669,31.372,23.703c0,18.824-19.52,29.629-31.023,39.389     c-10.108,8.714-23.354,23.006-23.354,52.983c0,18.125,4.879,23.354,19.171,23.354c17.08,0,20.565-7.668,20.565-14.291     c0-18.126,0.35-28.583,19.521-43.571c9.411-7.32,39.04-31.023,39.04-63.789S297.307,140.002,252.69,140.002z" fill="#0081cf" data-original="#000000" style="" class="" />
                    <path d="M256,0C114.516,0,0,114.497,0,256v236c0,11.046,8.954,20,20,20h236c141.483,0,256-114.497,256-256     C512,114.516,397.503,0,256,0z M256,472H40V256c0-119.377,96.607-216,216-216c119.377,0,216,96.607,216,216     C472,375.377,375.393,472,256,472z" fill="#0081cf" data-original="#000000" style="" class="" />
                  </svg>
                </a>
                <div class="dropdown-menu options" aria-labelledby="dropdownMenuLink" style="padding:20px;">
                  <paper-displayer :value="[ { ruleType:'readOnly', condition:  'services.completed == \'yes\' && services.createdBy == \'createdBy\''   } ]" />
                </div>
              </div>
              <textarea id="custom-rules" rows="5" :value="JSON.stringify(customItem.rules) " v-on:keyup="validationInputJSON('custom-rules', 'rules')"></textarea>
            </div> 
            <div  class="input-wrap col-md-12"> 
              <label > Layout Additionl Description </label>
              <textarea rows="5" v-model="customItem.description"></textarea>
            </div> 
            <div class="input-wrap col-md-12"> 
              <label > Layout Help </label>
              <textarea name="" id="" cols="30" rows="5" v-model="customItem.help"></textarea>
            </div>
            <div class="input-wrap col-md-12"> 
              <label >Query Parameters (GRAPHQL, Preload List) &nbsp;</label>
              <div class="dropdown show labelOptions">
                <a role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <svg   width="12" height="12" x="0" y="0" viewBox="0 0 512 512" style="enable-background: new 0 0 512 512" xml:space="preserve" class="">
                    <path d="M248.158,343.22c-14.639,0-26.491,12.2-26.491,26.84c0,14.291,11.503,26.84,26.491,26.84     c14.988,0,26.84-12.548,26.84-26.84C274.998,355.42,262.799,343.22,248.158,343.22z" fill="#0081cf" data-original="#000000" style="" class="" />
                    <path d="M252.69,140.002c-47.057,0-68.668,27.885-68.668,46.708c0,13.595,11.502,19.869,20.914,19.869     c18.822,0,11.154-26.84,46.708-26.84c17.429,0,31.372,7.669,31.372,23.703c0,18.824-19.52,29.629-31.023,39.389     c-10.108,8.714-23.354,23.006-23.354,52.983c0,18.125,4.879,23.354,19.171,23.354c17.08,0,20.565-7.668,20.565-14.291     c0-18.126,0.35-28.583,19.521-43.571c9.411-7.32,39.04-31.023,39.04-63.789S297.307,140.002,252.69,140.002z" fill="#0081cf" data-original="#000000" style="" class="" />
                    <path d="M256,0C114.516,0,0,114.497,0,256v236c0,11.046,8.954,20,20,20h236c141.483,0,256-114.497,256-256     C512,114.516,397.503,0,256,0z M256,472H40V256c0-119.377,96.607-216,216-216c119.377,0,216,96.607,216,216     C472,375.377,375.393,472,256,472z" fill="#0081cf" data-original="#000000" style="" class="" />
                  </svg>
                </a>
                <div class="dropdown-menu options" aria-labelledby="dropdownMenuLink" style="padding:20px;">
                  <paper-displayer :value="{entityType:'ORGANIZATION/SYSTEM',shortEntity:'#',fieldID:' att ',fieldValue:' att '}" />
                </div>
              </div>
              <textarea id="custom-metaJSON" rows="5" :value="JSON.stringify(customItem.metaJSON) " v-on:keyup="validationInputJSON('custom-metaJSON', 'metaJSON')"></textarea>
            </div> 
          </div>
          <!-- Layout -->
          <div v-if="customItem.metaType == 'metaModule' || customItem.metaType == 'customModule'" class="elemet-wrap row col-md-12"  style="background: #00F5C93d; margin:10px 0;">
            <h6 class="col-md-12" style=" padding: 10px">Module</h6>
            <div  class="input-wrap col-md-12" v-for="(item, index) in record.idioms" :key="index" >
              <label > Text {{item.toUpperCase()}}</label>
              <textarea rows="5" v-model="customItem.text[item]" ></textarea>
            </div>
            <div v-if="customItem.metaType == 'customModule'"  class="input-wrap col-md-12" >
              <label > URL / Image </label>
              <input type="text" v-model="customItem.url"  />
            </div>

          </div>
          <!-- Perrmisson -->
          <div class="elemet-wrap row col-md-12"  style="background: #ffbc003d;">
            <h6 class="col-md-12" style=" padding: 10px"> Permissions </h6>
            <div class="input-wrap col-md-6"> 
              <label for=""> Groups Can Edit</label> <br>
              <div class="envelop" v-for="(check, zindex) in permissions" :key="zindex">
                <input type="checkbox" class="box" :value="check.value" v-model="customItem.groupsCanEdit"/>
                <span class="text" >&nbsp; {{check.value}} </span>
              </div>
            </div> 
            <div class="input-wrap col-md-6"> 
              <label for="">Groups Can Read</label><br>
              <div class="envelop" v-for="(check, zindex) in permissions" :key="zindex">
                <input type="checkbox" class="box" :value="check.value" v-model="customItem.groupsCanRead"/>
                <span class="text" >&nbsp; {{check.value}} </span>
              </div>
            </div> 
            <div class="input-wrap col-md-6"> 
              <label for="">User Can Edit</label>
              <br>
              <input type="checkbox" class="box" value="@author" v-model="customItem.usersCanEdit"/>
              <span class="text" >&nbsp; @Author </span>
              <br>
              <input type="checkbox" class="box" value="@client" v-model="customItem.usersCanEdit"/>
              <span class="text" >&nbsp; @Client </span>
              <br>
              <input type="checkbox" class="box" value="@responsible" v-model="customItem.usersCanEdit"/>
              <span class="text" >&nbsp; @Responsible </span>
            </div> 
            <div class="input-wrap col-md-6"> 
              <label for="">User Can Read</label>
              <br>
              <input type="checkbox" class="box" value="@author" v-model="customItem.usersCanRead"/>
              <span class="text" >&nbsp; @Author </span>
              <br>
              <input type="checkbox" class="box" value="@client" v-model="customItem.usersCanRead"/>
              <span class="text" >&nbsp; @Client </span>
              <br>
              <input type="checkbox" class="box" value="@responsible" v-model="customItem.usersCanRead"/>
              <span class="text" >&nbsp; @Responsible </span>
            </div> 
          </div>
          <paper-displayer :value="customItem" /> 
        </div>
        <div class="action">
          <ui-button title="Save" text="" class="absoluteCenterFlex" style="width: calc(20% - 10px); float:left; margin:0 5px; padding: 12px;" color="green" v-on:click="applyCustomItem([customItem,'edit'])" data-dismiss="modal">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="white" d="M380.44 32H64a32 32 0 0 0-32 32v384a32 32 0 0 0 32 32h384a32.09 32.09 0 0 0 32-32V131.56ZM112 176v-64h192v64Zm223.91 179.76a80 80 0 1 1-83.66-83.67a80.21 80.21 0 0 1 83.66 83.67Z"/></svg>
          </ui-button>
          <ui-button title="Clone" class="absoluteCenterFlex" text=""  style="width: calc(20% - 10px); float:left; margin:0 5px; padding: 12px;" color="blue" v-on:click="applyCustomItem([customItem,'clone'])" >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 36 36"><path fill="white" d="M24 10V6a2 2 0 0 0-2-2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h4V12a2 2 0 0 1 2-2Z" class="clr-i-solid clr-i-solid-path-1"/><path fill="white" d="M30 12H14a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V14a2 2 0 0 0-2-2Zm-2 11h-5v5h-2v-5h-5v-2h5v-5h2v5h5Z" class="clr-i-solid clr-i-solid-path-2"/><path fill="none" d="M0 0h36v36H0z"/></svg>
          </ui-button>
          <ui-button title="Create SMC" class="absoluteCenterFlex" text=""  style="width: calc(20% - 10px); float:left; margin:0 5px; padding: 12px;" color="orange" v-on:click="createSMC(customItem)" >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="white" d="M14 16h2v-2h2v-2h-2v-2h-2v2h-2v2h2ZM4 20q-.825 0-1.412-.587Q2 18.825 2 18V6q0-.825.588-1.412Q3.175 4 4 4h6l2 2h8q.825 0 1.413.588Q22 7.175 22 8v10q0 .825-.587 1.413Q20.825 20 20 20Z"/></svg>
          </ui-button>
          <ui-button title="Remove" class="absoluteCenterFlex" text=""  style="width: calc(20% - 10px); float:left; margin:0 5px; padding: 12px;" color="red" v-on:click="applyCustomItem([customItem, 'delete'])" data-dismiss="modal">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="white" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>
          </ui-button>
          <ui-button title="Close" text="X" style="width: calc(20% - 10px); float:left; margin:0 5px;" data-dismiss="modal" />
        </div>
      </template>
    </ui-modal>
    <ui-modal name="web-preview" type="web-preview" classModal="modal-dialog-centered" >
      <template #content v-if="webSite != null">
        <ui-button text=" Save" color="green" style="padding:6px 10px; margin: 0 auto 20px; width: 200px; display: block;"/>
        <div class="smart-wrap">
          <div class="input-wrap">
          <select name="" id="" v-model="widthDisplayWebSite" v-on:change="renderWebContainer();" >
            <option :value="index" v-for="(item, index) in displayWebSite" :key="index">
              {{index}}
            </option> 
          </select>
        </div>
        </div>
        
      </template>
    </ui-modal>
    <paper-modal />
  </section>
</template>
<script>

//Tools
import Vuex from "vuex";
import { Auth, API, Storage } from "aws-amplify";
import { popUp, loading, validationInputJSON, processLayout, replaceCssVars } from "../../store/tools";
import { v4 as uuidv4 } from 'uuid';  
import Swal from 'sweetalert2'

//Components
import draggable from "vuedraggable";

import UiButton from "../../components/ui/UiButton.vue";
import UiModal from "../../components/ui/UiModal.vue";
import UiAccionButton from "../../components/ui/UiAccionButton.vue";

import PaperModal from "../../components/papers/PaperModal.vue";
import PaperWebConstructor from "../../components/papers/PaperWebConstructor.vue";
import PaperConstructor from "../../components/papers/PaperConstructor.vue";
import PaperDisplayer from "../../components/papers/PaperDisplayer.vue";
import PaperLayers from "../../components/papers/PaperLayers.vue";
import PaperLayout from "../../components/papers/PaperLayout.vue";
import PaperRenderSML from "../../components/papers/PaperRenderSML.vue";

//Back End
import { smd_getRecord2, smd_listEntityObj, smd_getWebPage, smd_generateGraphicPost, smd_getRecordObj, smd_generateWebSite, smd_applyCSS, smd_getChildren } from "../../graphql/queries";
import { smd_updateSmartLayout, smd_updateAttributes, smd_updateSmartRecord} from "../../graphql/mutations";
export default {
  name: "smart-maker",
  components:{
    //Ui
    UiModal,
    UiButton,
    UiAccionButton,
    //Papers
    PaperDisplayer,
    PaperLayers,
    PaperWebConstructor,
    PaperConstructor,
    PaperLayout,
    PaperModal,
    //tools
    draggable,
    PaperRenderSML,
  },
  data() {
    return {
      color1:'#000',
      color2:'#000',
      color3:'#000',
      color4:'#000',
      color5:'#000',
      //General
      record: null, // Data of Record ( Attributes, PK, Sk, ... )
      post: null,
      //Mode of Maker
      mode:'sml',
      modeMaker:'design',
      modePost:'preview',
      viewTextureValue : true,
      logoView:0,
      SMLPost: null,
      
      //Component Managment
      customPanel:{
        search:'',
        focusComponentType:'#MEL',
        componentList: [],
        configStyle: [],
        configStyleSelected:null,
        language:'en',
        palette:['Color 0','Color 1','Color 2','Color 3','Color 4','Black','White'],
        assets:[],
        PK:'#SMARTDASH',
        fonts:["'Warpaint'","'Gloss And Bloom'","'Rolling'","'LEMON MILK'","'Imstory'","'bunderan'"],
        //SML Options
        smlList: [],
        smlChilds: [],
      },
      //Tools
      layoutVersions: [], //PENDING
      srcIdioms:{
        en:'English',
        es:'Spanish',
        it:'Italian',
        fr:'French',
        de:'German',
      },
      //Publish
      webSite:null,
      displayWebSite: {
        displayDesk:'100%',
        displayLaptop:'992px',
        displayTablet:'767px',
        displayPhone:'450px',
      },
      HTMLWebSite: {
        displayDesk:'',
        displayLaptop:'',
        displayTablet:'',
        displayPhone:'',
      },
      widthDisplayWebSite:'displayDesk',
      renderWeb: false,
      
    }
  },
  created() {
    loading('show','Init Smart Maker')
    let item = this.$router.currentRoute.value.query
    this.customPanel.fonts = this.srcFonts
    let stickyPanel = false
    if (item.post && !item.PK) {
      item.PK="#SMARTDASH"
      item.SK="#SML#b6e290ce-fe1c-4872-9344-826d02302fe0"
    }
    if (this.$router.currentRoute.value.query.SK) {
      console.log( "%cEdit\nPK:" + item.PK + ",  SK:" + item.SK, "color:#6BF01A"
      );
      item.value = 'Default'
      this.SMLPost = item
      this.getLayout(item)
    } else {
      this.record = {
        PK: item.PK,
        SK: item.SK,
        shortEntity:"#SML",
        active: "1",
        bodyStyle: "",
        category: "",
        fontScript: "",
        fonts: {Title: "'Marck Script'", SubTitle: "'Montserrat'", Text: "'Montserrat'", SubText: "'Montserrat'"},
        groupsCanEdit:  ['', 'Superadmin'],
        groupsCanRead: ['', 'Public'],
        idioms: ['en'],
        palette: ['#fff', '#ccc', '#000', '#ccc', '#fff', '#000', '#fff'],
        scripts: "",
        usersCanEdit: ['Admin', 'Superadmin', 'Editor'],
        usersCanRead: ['Admin', 'Superadmin', 'Editor'],
        layout:[]
      }
      this.setCustomLayout([])  
      this.setWebSite(this.record)
      console.log("%cNew\nPK:" + item.PK, "color:#6BF01A");
    }
    
    
    $(window).scroll(function(event) {
      let scrollTop = $(window).scrollTop();
      if (scrollTop > 350 && stickyPanel == false) {
        document.getElementById('stickyPanel').classList.add('sticky')
        stickyPanel = true
      }
      if (scrollTop < 350 && stickyPanel == true) {
        document.getElementById('stickyPanel').classList.remove('sticky')
        stickyPanel = falsegraphicPost
      }
    });
    setTimeout(() => {
      //Purchase
      //console.clear()
      if (!item.mode) {
        item.mode = 'design'
      }
      this.modeMaker = item.mode
      //this.webPreview(false)
      loading('hidde', 'New Item')
    }, 1000);
    setTimeout(() => {
      if (this.$router.currentRoute.value.query.publish == 'true') {
        var save = new Event('click');
        let buttonSave = document.getElementById('pubishButton')
        console.log(buttonSave);
        buttonSave.dispatchEvent(save);
      }
    }, 1500);
    this.getComponents('CSS')
    this.getComponents('#SML')
  },
  methods: {
      ...Vuex.mapMutations(['setCustomLayout','setWebSite','applyCustomItem','setgRecord']), 
    modeMakerStatus(data){
      this.modeMaker = data
    },
    //Actions Layout
    refresh(){
      location.reload() 
    },
    async publishSML(){
      this.customPanel.smlChilds = []
      this.modeMaker = 'generate'
      let pushData = {}
      let pullData =''
      try {
        pushData ={
          PK: this.record.PK ,SK:  this.record.SK
        }
        console.log(' >> Push Data <<', pushData);
        pullData = await API.graphql({
          query: smd_getChildren, //smd_updateSmartRecord
          variables: pushData
        });
        pullData = pullData.data.smd_getChildren
        pullData = JSON.parse(pullData.data)
        setTimeout(() => {
          this.customPanel.smlChilds = pullData
          if (this.$router.currentRoute.value.query.publish == 'true') {
            window.close()
          }
        }, 1000);
        console.log(pullData);
      } catch (error) {
        popUp(error, 'cach', 'err')
        console.log(errors);
      }
    },
    setConfigStyles(data){
        try {
          let att = data.attributes
          const hexRegex = /#[0-9A-Fa-f]{6}\b/g;
          this.record.palette =  att.colors.attributeValue.match(hexRegex);
          this.record.fonts = [ att.font0.attributeValue, att.font1.attributeValue, att.font2.attributeValue, att.font3.attributeValue]
          this.record.fontScript = att.fontsLink.attributeValue
          document.head.insertAdjacentHTML("beforeend", this.record.fontScript)
          
          const replacements = {
            'var(--font0)': this.record.fonts[0],
            'var(--font1)': this.record.fonts[1],
            'var(--font2)': this.record.fonts[2],
            'var(--font3)': this.record.fonts[3],
          };
          
          this.record.class = att.classes.attributeValue
          if (this.record.class && this.record.class != '') {
            document.head.insertAdjacentHTML("beforeend", '<style>'+replaceCssVars(this.record.class, this.record)+'</style>')
          }
          this.record.configStyle = data.SK
        
      } catch (error) {
        console.log(this.record)
        console.log(error)
        
      }
      this.setWebSite(this.record)
    },
    viewResponsive(){
      setTimeout(() => {
        
        for (const key in this.HTMLWebSite) {
          document.getElementById(key+'View').innerHTML = this.HTMLWebSite[key]
          //console.log(document.getElementById(key+'View'));
        }
      }, 500);
    },
    listAssets(){
      Storage.list(this.customPanel.PK+'/') // for listing ALL files without prefix, pass '' instead
        .then(result => {
          for (let index = 0; index < result.length; index++) {
            const el = result[index];
            result[index].url = 'https://smartdashs3120108-dev.s3.amazonaws.com/public/'+result[index].key.replace('#','%23')
            result[index].attributeName = 'img' 
          }
          this.customPanel.assets = result
        })
        .catch(err => console.log(err));
    },
    async getLayout(data){
      this.listAssets()
      let pullData = null
      console.log(data);
      try {
        pullData = await API.graphql({
          query: smd_getRecord2,
          variables: {
            PK: data.PK,
            SK: data.SK,
          },
        });
        pullData = pullData.data.smd_getRecord2 
        //Response ---
        if (pullData.smd_response.statusCode != 200) {  
          popUp(pullData.smd_response, 'response', 'err')
          loading('hidde', 'getItem')
          return
        }
        else if (pullData.smd_response.popAlert == true) {
          popUp(pullData.smd_response, 'response', 'scc')
        }
        //Response ---
        pullData = JSON.parse(pullData.data)
        let record = pullData
        if (record.shortEntity == "#SMC") {
          pullData.attributes.forEach(att => {
            switch (att.attributeName) {
              case 'name':  
                record.name = att.attributeValue
              break;
              case 'layouttype':  
                record.layoutType = att.attributeValue
              break;
              case 'data':
                console.clear()
                console.log(JSON.parse(att.attributeValue));
                this.setCustomLayout([])  
                this.smartComponents = [JSON.parse(att.attributeValue)]
                this.setCustomLayout(this.smartComponents)  
                console.log(this.smartComponents);
                record.layout = this.smartComponents
              break;
            }
          });
          record.idioms = ['en']
        } else {
          pullData.attributes.forEach(att => {
            switch (att.attributeName) {
              case 'smartlayout.name':  
                record.name = att.attributeValue
              break;
              case 'smartlayout.layouttype':  
                record.layoutType = att.attributeValue
              break;
              case 'smartlayout.Layout':
                this.setCustomLayout([])  
                function filterLayout( layout ) {
                  for (let vindex = 0; vindex < layout.length; vindex++) {
                    switch (layout[vindex].metaType) {
                      case 'Layout':
                        layout[vindex].metaType = 'metaLayout'
                        layout[vindex].layoutItemType = 'backEnd'
                      break;
                      case 'metaLayout':
                        layout[vindex].layoutItemType = 'backEnd'
                      break;
                      case 'metaInput':
                        layout[vindex].layoutItemType = 'backEnd'
                      break;
                    }
                    
                    if (layout[vindex].att) {
                      for (const key in layout[vindex].att) {
                        if (key != 'smartComponents') {
                          layout[vindex][key] = layout[vindex].att[key] 
                        }
                      }
                      delete layout[vindex].att
                    }
                    switch (layout[vindex].isRepeatable) {
                      case "No":
                        layout[vindex].isRepeatable = false
                      break;
                      case "Yes":
                        layout[vindex].isRepeatable = true
                      break;
                    }
                    if (layout[vindex].metaType == 'metaElement') {
                      if (layout[vindex].elementType == 'Title' && !layout[vindex].titleType  ) {
                        layout[vindex].titleType = 'h4'
                      }
                    }
                    if (layout[vindex].groupsCanEdit == undefined) { layout[vindex].groupsCanEdit = ["Admin","Editor","Superadmin"] }
                    if (layout[vindex].groupsCanRead == undefined) { layout[vindex].groupsCanRead = ["Admin","Editor","Superadmin"] }
                    if (layout[vindex].usersCanEdit == undefined) { layout[vindex].usersCanEdit = ["@author"] }
                    if (layout[vindex].usersCanRead == undefined) { layout[vindex].usersCanRead = ["@author"] }
                    if (layout[vindex].id == undefined) { layout[vindex].id = uuidv4() }
                    if (layout[vindex].version == undefined) { layout[vindex].version = '1' }
                    if (layout[vindex].print == undefined) { layout[vindex].print = true }
                    if (layout[vindex].styleWrap == undefined) { layout[vindex].styleWrap = layout[vindex].style }
                    if (typeof layout[vindex].text == 'string') { layout[vindex].text = {en:layout[vindex].text} }
                    if (layout[vindex].smartComponents.length > 0 ) { layout[vindex].smartComponents = filterLayout(layout[vindex].smartComponents) }
                    if (layout[vindex].style == undefined || typeof layout[vindex].style != 'object' ) {
                      layout[vindex].style = {
                        width:['50%','100%','100%','100%'],
                        widthC:['100%','100%','100%','100%'],
                        height:['auto','auto','auto','auto'],
                        heightC:['auto','auto','auto','auto'],
                        angle: 0,
                        padding:'10px',
                        margin:'0px',
                        font:{
                          size:['14px','14px','14px','14px'],
                          weight:'',
                          font: ["'Montserrat'","'Montserrat'","'Montserrat'","'Montserrat'"],
                          color:'',
                        },
                        bgc:'',
                        border:{
                          weight:'',
                          type:'',
                          color:'',
                        },
                      }
                    }
                    if (typeof layout[vindex].style.font.size != 'object' ) { layout[vindex].style.font.size = [] }
                    if (!layout[vindex].style.heightC ) { layout[vindex].style.heightC = [] }
                    if (!layout[vindex].style.heightC ) { layout[vindex].style.heightC = [] }
                  }
                  return layout
                }
                this.smartComponents = filterLayout(JSON.parse(att.attributeValue))   
                this.setCustomLayout(this.smartComponents)  
                record.layout = this.smartComponents
              break;
              case 'category':
                record.category = att.attributeValue
              break;
              case 'smartlayout.description':
                record.description = att.attributeValue
              break;
              case 'smartlayout.attributeName':
                record.attributeName = att.attributeValue
              break;
              case 'smartlayout.versions':
                //record.layoutVersions = JSON.parse(att.attributeValue)///------------------
              break;
              case 'idioms':
                record.idioms = JSON.parse(att.attributeValue)
              break;
              case 'path':
                record.path = att.attributeValue
              break;
              case 'appCode':
                record.appCode = att.attributeValue
                
              break;
              case 'templateType':
                record.templateType = att.attributeValue
              break;
              
              case 'template':
                record.template = att.attributeValue
              break;
              case 'palette':
                record.palette = JSON.parse(att.attributeValue)
              break;
              case 'bodyStyle':
                record.bodyStyle = att.attributeValue
              break;
              case 'scripts':
                record.scripts = JSON.parse(att.attributeValue)
              break;
              case 'parent':
                if (att.attributeValue) {
                  
                  record.parent = JSON.parse(att.attributeValue)
                }
              break;
              case 'configStyle':
                console.clear()
                record.configStyle = att.attributeValue
                //this.customPanel.configStyle
                setTimeout(() => {
                  let index = this.customPanel.configStyle.findIndex(obj => obj.SK === record.configStyle);
                  this.configStyleSelected = index
                  this.setConfigStyles(this.customPanel.configStyle[index])
                }, 1000);
              break;
              case 'fontScript':
                record.fontScript = att.attributeValue
                document.head.insertAdjacentHTML("beforeend", record.fontScript)
              break;
              case 'fonts':
                try {
                  record.fonts = JSON.parse(att.attributeValue)
                  if (record.fonts.Title) {
                    record.fonts = [ record.fonts.Title, record.fonts.SubTitle, record.fonts.Text, record.fonts.SubText]
                  }
                } catch (error) {
                  record.fonts = [ "'Montserrat'", "'Montserrat'", "'Montserrat'", "'Montserrat'"]
                }
              break;
              case 'class':
                console.log( record.fonts);
                record.class = att.attributeValue
              break;
            }
          });
          delete record.attributes
          delete record.strAtt

          // Definir un objeto que mapea las variantes numeradas de las palabras a reemplazar
          if (record.class && record.palette) {
            const replacements = {
              'var(--color0)': record.palette[0],
              'var(--color1)': record.palette[1],
              'var(--color2)': record.palette[2],
              'var(--color3)': record.palette[3],
              'var(--color4)': record.palette[4],
              'var(--color5)': record.palette[5],
              'var(--color6)': record.palette[6],
              'var(--font0)': record.fonts[0],
              'var(--font1)': record.fonts[1],
              'var(--font2)': record.fonts[2],
              'var(--font3)': record.fonts[3],
            };

            // Utilizar una expresión regular para buscar las palabras a reemplazar y la función replace() para hacer el reemplazo
            
            const replacedString = record.class.replace(/var\(--(color|font)\d\)/g, match => replacements[match]);
            document.head.insertAdjacentHTML("beforeend", '<style>'+replacedString+'</style>')
          }
        }
        if (!record.idioms) {
          record.idioms = ['en']
        }
        if (!record.palette) {
          record.palette = ['#F2F2F2','#BFBFBF','#8C8C8C','#262626','#0D0D0D','#000','#fff']
        }
        if (!record.fonts || record.fonts.length == 0 ) {
          record.fonts = [ "'Montserrat'", "'Montserrat'", "'Montserrat'", "'Montserrat'"]
        }
        if (!record.bodyStyle) {
          record.bodyStyle = ''
        }
        if (!record.scripts) {
          record.scripts = ''
        }
        if (!record.appCode) {
          record.appCode = ''     
        }
        this.record = record
        this.setWebSite(record)
        
        if (this.$router.currentRoute.value.query.post) {
          this.mode = 'post'
          this.generatePost( data)
          
        }else{
          this.getComponents('#MEL')
        }
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      loading('hidde', 'getItem')
    },
    async updateLayout(){
      loading('show','update layout');
      if (!this.record.parent) {
                  
        this.record.parent = ''
        }
        if (!this.record.appCode) {
        this.record.appCode = ''
      }
      let pushData = {
        shortEntity: "#SML",
        SK: this.record.SK,
        PK: this.record.PK,
        entity: {
          PK :'#SMARTDASH',
          SK:'#ENT#7f7099f5-8e1c-4a0b-b5da-e8f7c1dc3c1f',
        },
        
        attributes: [
          { 
            attributeName: 'smartlayout.name',
            attributeType: "S",
            attributeValue: this.record.name
          },
          { 
            attributeName: 'parent',
            attributeType: "L",
            attributeValue: JSON.stringify(this.record.parent)
          },
          { 
            attributeName: 'smartlayout.Layout',
            attributeType: "L",
            attributeValue: JSON.stringify(this.customLayout)
          },
          { 
            attributeName: 'smartlayout.versions',
            attributeType: "L",
            attributeValue: JSON.stringify([])
          },
          { 
            attributeName: 'smartlayout.description',
            attributeType: "S",
            attributeValue: this.record.description
          },
          { 
            attributeName: 'smartlayout.layouttype',
            attributeType: "S",
            attributeValue: this.record.layoutType
          },
          { 
            attributeName: 'smartlayout.attributeName',
            attributeType: "S",
            attributeValue: this.record.attributeName
          },
          { 
            attributeName: 'smartlayout.itemModel',
            attributeType: "S",
            attributeValue: ''
          },
          { 
            attributeName: 'category',
            attributeType: "S",
            attributeValue: this.record.category
          },
          { 
            attributeName: 'configStyle',
            attributeType: "S",
            attributeValue: this.record.configStyle
          },
          { 
            attributeName: 'idioms',
            attributeType: "L",
            attributeValue: JSON.stringify(this.record.idioms)
          },
          { 
            attributeName: 'palette',
            attributeType: "L",
            attributeValue: JSON.stringify(this.record.palette)
          },
          { 
            attributeName: 'bodyStyle',
            attributeType: "S",
            attributeValue: this.record.bodyStyle
          },
          { 
            attributeName: 'scripts',
            attributeType: "L",
            attributeValue: JSON.stringify(this.record.scripts)
          },
          { 
            attributeName: 'fontScript',
            attributeType: "S",
            attributeValue: this.record.fontScript
          },
          { 
            attributeName: 'class',
            attributeType: "S",
            attributeValue: this.record.class
          },
          { 
            attributeName: 'fonts',
            attributeType: "L",
            attributeValue: JSON.stringify(this.record.fonts)
          },
          
          { 
            attributeName: 'path',
            attributeType: "S",
            attributeValue: this.record.path
          },
          { 
            attributeName: 'appCode',
            attributeType: "S",
            attributeValue: this.record.appCode
          },
          { 
            attributeName: 'template',
            attributeType: "S",
            attributeValue: this.record.template
          },
          { 
            attributeName: 'templateType',
            attributeType: "S",
            attributeValue: this.record.templateType
          } ,
        ]
        ,
        //strAtt: JSON.stringify(this.smartComponents).replace(/\\/gi, ""),
        groupsCanRead: this.record.groupsCanRead,
        groupsCanEdit: this.record.groupsCanEdit,
        usersCanEdit:  this.record.usersCanEdit,
        usersCanRead:  this.record.usersCanRead,
      }
      if (this.record.parent) {
        pushData.parent=  {
          PK: this.record.parent.PK,
          SK: this.record.parent.SK,
        }
      }
      
      
      this.webSite =null
      console.log('Data to update SML:', pushData);
      if ( !pushData.PK || !pushData.shortEntity ) {
        console.log("Error en datos");
      } else {
        try {
          const push = await API.graphql({
            query: smd_updateSmartLayout,
            variables: { input: pushData },
          });
          console.log("Creado exitosamente",push);
          if (!this.record.SK) {
            window.close();
          }else{
            this.getLayout( this.record);
          }
        } catch (error) {
          console.log(error);
          popUp(error, 'cach', 'err')
        }
      }
      this.modeMaker = 'design'
      loading('hidde');
    },
      //Action PostZ
    async generatePost(sml){
      let pullData = ''
      let pushData = {}
      try {
        pushData = {
          PK: this.organizationID,
          SK: this.$router.currentRoute.value.query.post ,
        }
        pullData = await API.graphql({
          query: smd_getRecordObj,
          variables:pushData
        })
        pullData = pullData.data.smd_getRecordObj
        console.log(pullData);
        pullData =JSON.parse(pullData.data)
        if (pullData.savedLayouts.value != "") {
          this.post =JSON.parse(pullData.savedLayouts.value)
          this.record.palette = this.post[0].palette[0]
          console.log(this.post);
          this.setWebSite(this.post[0])
          this.setCustomLayout(this.post[0].versions[0].layout)  
        }else{
          pushData ={
            itemPK:this.organizationID,
            itemSK:this.$router.currentRoute.value.query.post,
            SMLPK:sml.PK,
            SMLSK:sml.SK,
          }
          console.log(pushData);
          try {
            pullData = await API.graphql({
              query: smd_generateGraphicPost,
              variables:pushData
            })
            pullData = pullData.data.smd_generateGraphicPost
            this.post =JSON.parse(pullData.data)
            for (let index = 0; index < this.post.length; index++) {
              const element = this.post[index];
              this.post[index].palette = element.palette
              if (typeof element.palette == 'string') {
                this.post[index].palette = JSON.parse(element.palette)
              }
              if (typeof element.logoSelection == 'string') {
                this.post[index].logoSelection = JSON.parse(element.logoSelection)
              }
              if (typeof element.textures == 'string') {
                this.post[index].textures = JSON.parse(element.textures)
              }
              if (typeof element.images == 'string') {
                this.post[index].images = JSON.parse(element.images)
              }
              
              for (let zindex = 0; zindex < element.versions.length; zindex++) {
                const version = element.versions[zindex];
                this.post[index].versions[zindex].layout = version.layout
                if (typeof version.layout == 'string') {
                  this.post[index].versions[zindex].layout = JSON.parse(version.layout)
                }
              }
            }
            if (typeof this.post[0].palette[0] == 'object') {
              this.record.palette = this.post[0].palette[0]
              this.record.palette[5] = "#000"
              this.record.palette[6] = "#FFF"
            }else{
            
              this.record.palette = this.post[0].palette
              this.record.palette[5] = '#000'
              this.record.palette[6] = '#fff'
            }
            this.setWebSite(this.record)
            this.setCustomLayout(this.post[0].versions[0].layout)  
    
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        }
        setTimeout(() => {
          
          this.modePost = 'edition' 
        }, 200);
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      return
    },
    randomFonts(){  
      console.clear()
      for (const key in this.record.fonts) {
        let font = ''
        do {
          let random = Math.floor(Math.random() * 100);
          if (this.srcFonts[random]) {
            font = this.srcFonts[random]
            console.log(font);
          }
        } while (!font);
        this.record.fonts[key] = font
      }
      this.setWebSite(this.record)
    },
    randomColor(){
      console.clear()
      let palette = ''
      do {
        let random = Math.floor(Math.random() * 100);
        if (this.post[0].palette[random]) {
          palette = this.post[0].palette[random]
        }
      } while (!palette);
      palette[5] = "#000"
      palette[6] = "#FFF"
      this.record.palette = palette
      this.setWebSite(this.record)
    },
    randomBackground(){
      console.clear()
      let texture = ''
      do {
        let random = Math.floor(Math.random() * 100);
        if (this.post[0].textures[random]) {
          texture = this.post[0].textures[random]
        }
      } while (!texture);
      console.log(processLayout( this.customLayout, 'randomTexture' , texture));
      //this.setCustomLayout(this.smartComponents)  
      this.record.layout = this.smartComponents
      this.setWebSite(this.record)
    },
    viewTexture(value){
      console.clear()
      this.setCustomLayout(processLayout( this.customLayout, 'viewTexture' , value))
    },
    randomLogo(){
      console.clear()
      let logos = ''
      this.logoView ++
      if (this.post[0].logos.length <= this.logoView) {
        this.logoView = 0
      }
      logos = this.post[0].logos[this.logoView ]
      console.log(this.logoView);
      processLayout( this.customLayout, 'randomLogo' , logos)
      //this.setCustomLayout(this.smartComponents)  
    },
    randomImage(){
      console.clear()
      let images = ''
      do {
        let random = Math.floor(Math.random() * 100);
        if (this.post[0].images[random]) {
          images = this.post[0].images[random]
        }
      } while (!images);
      processLayout( this.customLayout, 'randomImage' , images)
    },
    reversePalette(){
      console.clear()

      this.record.palette = this.record.palette.splice(0,5).reverse()
      this.record.palette[5] = "#000"
      this.record.palette[6] = "#FFF"
      this.setWebSite(this.record)
    },
    async saveVersions(action){
      let push = ''
      let layout = ''
      if (action == 'clear') {
        try {
          layout = [{
            attributeName: 'savedLayouts',
            attributeValue: '',
            attributeType: 'L',
          }]
          push = await API.graphql({
            query: smd_updateAttributes, //smd_updateSmartRecord
            variables: { 
              PK: this.organizationID,
              SK: this.$router.currentRoute.value.query.post ,
              attributes: JSON.stringify(layout) ,
            },
          });
          console.log("Creado exitosamente");
          push = push.data.smd_updateAttributes
          console.log(push);
        } catch (error) {
          console.log(error);
        }
      } else {
        this.post[0].palette.splice(this.post[0].palette.indexOf(this.record.palette), 1); 
        this.post[0].palette.splice(0, 0, this.record.palette); 
        this.post[0].versions[0].layout = this.customLayout
        
        layout = [{
          attributeName: 'savedLayouts',
          attributeValue: JSON.stringify(this.post),  
          attributeType: 'L',
        }]
        console.log(this.post);
        try {
          push = await API.graphql({
            query: smd_updateAttributes, //smd_updateSmartRecord
            variables: { 
              PK: this.organizationID,
              SK: this.$router.currentRoute.value.query.post ,
              attributes: JSON.stringify(layout) ,
            },
          });
          push = push.data.smd_updateAttributes
          console.log(push);
        } catch (error) {
          console.log(error);
        }
      }
      this.showVersions = true 
    },
    //Dragg And Drop Functionality
    async getComponents(data){
      console.log(data);
      let PK = '#SMARTDASH'
      if (data == "CSS" || data == "#SML") {
        this.customPanel.configStyle = []
        PK = this.organizationID
      }
      switch (data) {
        case '#MEL':
          this.customPanel.componentList = this.elementList
        break;
        default :
          try {
            let pushData = []
            let pullData = await API.graphql({
              query: smd_listEntityObj,
              variables: {
                active: '1' ,
                PK: PK,
                shortEntity: data,
                pageSize: 0 ,
              },
            });
            pullData = pullData.data.smd_listEntityObj
            let currentData = JSON.parse(pullData.data)
            if (pullData.smd_response.statusCode != 200) {
              popUp(pullData.smd_response, 'response', 'err')
              loading('hidde', 'getItem')
              return
            }
            else if (pullData.smd_response.popAlert == true) {
              popUp(pullData.smd_response, 'response', 'scc')
            }
            console.log(data);
            console.log(currentData);
            for (let index = 0; index < currentData.length; index++) {
              const el = currentData[index];
              switch (data) {
                case '#SML':
                  this.customPanel.smlList.push(el)
                break;
                case 'CSS':
                  this.customPanel.configStyle.push(el)
                break;
                case '#SMC':
                  console.log(el);
                  let preview = ''
                  if (el.attributes.preview) {
                    preview =JSON.parse(el.attributes.preview.attributeValue)
                  }
                  pushData.push(
                    {
                      ...JSON.parse(el.attributes.data.attributeValue),
                      name:el.attributes.name.attributeValue,
                      SK:el.SK,
                      PK:el.PK,
                      form: el.form,
                      shortEntity:  el.shortEntity,
                      entity: el.entity,
                      preview: preview
                    }
                  )
                break;
                default:
                  pushData.push({
                    PK: el.PK,
                    SK: el.SK,
                    name: el.attributes['modules.pageName'].attributeValue,
                    attributeName: el.attributes['modules.dataQuery'].attributeValue,
                    metaType: 'metaModule',
                    layoutItemType:'backEnd',
                  })
                break;
              }
            }
            console.log(pushData);
            this.customPanel.componentList = pushData
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        break;
      }
    },
    dropComponent({attributeName, metaType, url, name, SK }){ 
      console.log(attributeName, metaType);
      let component = {}  
      
      try {
        switch (metaType) {
          case 'metaModule':
            for (let index = 0; index < this.customPanel.componentList.length; index++) {
              const el = this.customPanel.componentList[index];
              if (attributeName == el.attributeName) {
                component = el
                component.t
              }
            }
          break;
          case 'metaLayout':
            component.isRepeatable= false
            component.layoutItemType='backEnd'
            component.layoutType= 'layout'
            component.metaType= 'metaLayout'
          break;
          default:
            for (let index = 0; index < this.elementList.length; index++) {
              const el = this.elementList[index];
              
              if (attributeName == el.attributeName) {
                component = el
                component.elementType = attributeName
                component.label = name
                
                component.metaType = 'metaElement'
                component.layoutItemType='frontEnd'
                if (url != undefined) {
                  component.url=url
                }
                if (attributeName == 'Input') {
                  component.metaType = 'metaInput'
                  component.layoutItemType='backEnd'
                  component.inputType='Text'
                  component.isRepeatable = false
                  if (name == 'Quote') {  component.inputType='quote' }
                  if (name == 'Calendar') {  component.inputType='calendar' }
                }
                if (attributeName == 'smartLayout') {
                  component.metaType = 'metaInput'
                  component.layoutItemType='backEnd'
                  component.inputType='Text'
                  component.isRepeatable = false
                  if (name == 'Quote') {  component.inputType='quote' }
                  if (name == 'Calendar') {  component.inputType='calendar' }
                }
                if (attributeName == 'Tag') {
                  component.metaType = 'Tag'
                }
                if (attributeName == 'paymentMethod') {
                  component.metaType = 'paymentMethod'
                }
                if (attributeName == 'menu') {
                  component.url = 'https://smartdashs3120108-dev.s3.amazonaws.com/public/ORG%23SMARTDASH/35c62da1-468a-4c5c-9a4f-36a163654d11.svg'
                }
                if (attributeName == 'metaModule') {
                  console.log('hola');
                  component.layoutItemType='backEnd'
                  component.metaType = 'customModule'
                }
              }
            } 
          break;
        }
        component.text ={ }
        for (let index = 0; index < this.record.idioms.length; index++) {
          const idiom = this.record.idioms[index];
          component.text[idiom] = 'Main Text'
          if (component.elementType == 'Title') { component.text[idiom] = 'Main Title' } //Ojito
        }
        component.usersCanEdit=[]
        component.usersCanRead=[]
        component.groupsCanEdit=["Admin","Superadmin","Editor"]
        component.groupsCanRead=["Admin","Superadmin","Editor"]
        component.style = {
          width:['50%','100%','100%','100%'],
          widthC:['100%','100%','100%','100%'],
          height:[],
          heightC:[],
          angle: 0,
          padding:'10px',
          margin:'0px',
          font:{
            size:[],
            weight:'',
            font: [],
            color:'',
          },
          bgc:'',
          border:{
            weight:'',
            type:'',
            color:'',
          },

        }
        //Menu Icon (Hamburger) https://smartdashs3120108-dev.s3.amazonaws.com/public/ORG%23SMARTDASH/35c62da1-468a-4c5c-9a4f-36a163654d11.svg
        //Menu Icon (Hamburger) https://smartdashs3120108-dev.s3.amazonaws.com/public/ORG%23SMARTDASH/35c62da1-468a-4c5c-9a4f-36a163654d11.svg
        if (!component.styleComponent) { component.styleComponent = '' }
        if (!component.styleWrap) { component.styleWrap = '' }
        if (!component.smartComponents) { component.smartComponents = [] }
         //texts 
        if (component.elementType == 'Title') { component.titleType = 'h4'; }
        
        component.attributeName= attributeName
        component.id = uuidv4()
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      if (this.customPanel.focusComponentType == "#SMC") {
        this.customPanel.componentList.forEach(el => {
          if (el.SK == SK ) {
            console.log(el);
            component = el
            function newID(element) {
              //console.log('Before ID: '+ element.id);
              element.id = uuidv4()
              //console.log('After ID: '+ element.id);
              if (element.smartComponents.length != 0) {
                  for (let index = 0; index < element.smartComponents.length; index++) {
                    newID(element.smartComponents[index])
                  }
              }
            }
            newID(component)
            console.log(component)
          }
        });
      }
      return component
    },

    // Functionality Maker
    inputHelp(name){
      
    },
    smartComponentEdit(component){
      this.setgRecord([component, 'update'])
      $('#modal-update').modal('show')
      return
      let url = this.$router.resolve( { path:'/smartmaker',query: { name:'SMLUP', PK:'#SMARTDASH', SK:component.SK, } });
      console.log(url)
      window.open(url.href, '_blank')
    },
    async applyCss(){
      console.clear()
      loading('show','webPreview')
      let pushData = {};
      let pullData = ''
      try {
        pushData ={
          itemPK: this.record.PK,
          itemSK: this.record.SK,
          CSSStyles: this.record.class,
          palette: JSON.stringify(this.record.palette),
          fonts: JSON.stringify(this.record.fonts),
        }
        console.log(pushData);
        pullData = await API.graphql({
          query: smd_applyCSS,
          variables: pushData
        });
        pullData = pullData.data.smd_applyCSS
        console.log(pullData);
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      loading('hidde', 'getItem')
    },
    renderWebContainer(){
      //console.clear()
      loading('show','1 ')
      var i = 0;  
      let view = ['displayDesk','displayLaptop','displayTablet', 'displayPhone']
      var that = this;
      that.renderWeb = true     
      function myLoop() {    
        setTimeout(function() {  
          that.HTMLWebSite[that.widthDisplayWebSite] = document.getElementById('container-web').innerHTML             
          that.renderWeb = false
          console.log(view[i]+' - ' + i, document.getElementById('container-web') , that.renderWeb);
          that.viewResponsive++
          i++;                    
          if (i < 4) {           
            setTimeout(() => {
              that.renderWeb = true     
            }, 100);
            that.widthDisplayWebSite = view[i]   
            myLoop();             
          }    
        }, 4000)
        return true
      }

      myLoop();  
      setTimeout(() => {
        console.log(that.HTMLWebSite);
        //this.HTMLWebSite[this.widthDisplayWebSite] = document.getElementById('container-web').innerHTML    
        this.renderWeb = false
        //this.viewResponsive()
        this.updateLayoutAtt()
        this.modeMaker = 'design';
        Swal.fire({
          icon: 'success',
          title: 'Successfully generated' ,
          //text: JSON.stringify(error),
        })
        loading('hidde','webPreview')
      }, 20000);
    },
    async webPreview( preview ){
      console.clear()
      loading('show','webPreview')
      let pushData = {};
      let pullData = ''
      this.HTMLWebSite = {
        displayDesk:'',
        displayLaptop:'',
        displayTablet:'',
        displayPhone:'',
      }
      try {
        pushData ={
          orgPK: this.record.PK,
          SMLPK: this.record.PK,
          SMLSK: this.record.SK
        }
        console.log('pushData Get Layout:', pushData);
        pullData = await API.graphql({
          query: smd_getWebPage,
          variables: pushData
        });
        this.webSite = {}
        pullData = pullData.data.smd_getWebPage
        pullData = JSON.parse(pullData.data)
        for (let index = 0; index < JSON.parse(pullData.SML).length; index++) {
          const element = JSON.parse(pullData.SML)[index];
          try {
            if (element.attributeType == 'L') {
              element.attributeValue =JSON.parse(element.attributeValue)
            }
          } catch (error) {
            console.log(error);
          }
          this.webSite[element.attributeName] =element.attributeValue
        }
        this.webSite.binds = pullData.binds
        
        this.webSite.layout = pullData.layout
        console.log('Web Site Data:', this.webSite);
        //this.setWebSite(this.webSite)
        /*
        this.language = this.SML.idioms[0]
        this.data = pullData.binds
        this.layout = pullData.layout
        this.palette = this.SML.palette
        this.bodyStyle = this.SML.bodyStyle
        this.setWebSite(this.SML)*/
        //document.head.insertAdjacentHTML("beforeend", this.SML.fontScript)
        setTimeout(() => {
            //if (preview != false) {
            //  $('#modal-web-preview').modal('show')
            //}
              console.log(this.HTMLWebSite);
              setTimeout(() => {
                this.renderWebContainer(0)
              }, 500);
          }, 100);
      } catch (error) {
        console.log(error);
      }
    },
    async generateWebSite(){
      //console.clear()
      let pushData = {};
      let pullData = ''
      try {
        pushData ={
          itemPK: this.record.PK,
          itemSK: this.record.SK,
          destination: this.record.path,
          template: this.record.template,
          HTML0: this.HTMLWebSite['displayDesk'],
          HTML1: this.HTMLWebSite['displayLaptop'],
          HTML2: this.HTMLWebSite['displayTablet'],
          HTML3: this.HTMLWebSite['displayPhone'],
          CSSStyles:this.record.class,
          fonts: JSON.stringify(this.record.fonts),
          palette: JSON.stringify(this.record.palette)
        }
        console.log(pushData);
        pullData = await API.graphql({
          query: smd_generateWebSite,
          variables: pushData
        });
        pullData = pullData.data.smd_generateWebSite
        console.log(pullData);
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      
      loading('hidde', 'getItem')
      location.reload();
    },
    backMaker(){
      //this.$router.go(-1)
      window.close();
    },
    searchComponent(){
      let value = this.customPanel.search
      let listItems = document.getElementById("listSmart");
      for (let vindex = 0; vindex < listItems.children.length; vindex++) {
        const element = listItems.children[vindex];
        if ((element.innerText.toLowerCase()).includes(value.toLowerCase()) ) {
          document.getElementById("listSmart").children[vindex].classList.remove('displayNone')
        }else{
          document.getElementById("listSmart").children[vindex].classList.add('displayNone')
        }
      }
    },
    validationInputJSON(id, att) {
      console.clear();
      let input = document.getElementById(id)
      console.log(input);
      console.log(input.value);
      try {
        this.customItem[att] = JSON.parse(input.value)
        input.style = ''
      } catch (error) {
        input.style = 'background: #ff3030; color: #fff;'
      }
    },
    setSetyleBoddy(data){
      let palette = this.record.palette
      let style = data

      let regexp = /\[.+?\]/g;
      let bind = [...style.matchAll(regexp)];

      for (let index = 0; index < bind.length; index++) {
        let parameter = JSON.parse(bind[index][0])
        style = style.replace(bind[index][0], palette[parameter[1]])
      }
      return style
    },
    async updateLayoutAtt(){
      loading('show', 'getItem')
      let pushData = ''
      let att = [{
          attributeName: 'path',
          attributeType: "S",
          attributeValue: this.record.path
        },
        { 
          attributeName: 'template',
          attributeType: "S",
          attributeValue: this.record.template
        } ,
        { 
          attributeName: 'templateType',
          attributeType: "S",
          attributeValue: this.record.templateType
        } ,
        { 
          attributeName: 'SEOKeyWords',
          attributeType: "S",
          attributeValue: ''
        },
        { 
          attributeName: 'outputHTML',
          attributeType: "S",
          attributeValue: "document.getElementById('webHTML').innerHTML"
      }]
      try {
        pushData = await API.graphql({
            query: smd_updateAttributes, //smd_updateSmartRecord
            variables: { 
              PK: this.record.PK,
              SK: this.record.SK ,
              attributes: JSON.stringify(att) ,
            },
        });
        this.generateWebSite()
      } catch (error) {
        popUp(error, 'cach', 'err')
      }
      //loading('hidde', 'getItem')
    },
    //SMC
    async createSMC(data){
      loading('show', 'getItem')
      console.clear()
      console.log(data);
      let query = { }
      let pushData = ''
      let response = ''
      let att= [
        {
          attributeName:'data',
          attributeType:'L',
          attributeValue: JSON.stringify(data)
        },
        {
          attributeName:'name',
          attributeType:'S',
          attributeValue: data.attributeName,
        },
      ]
      try {
        //Set Mode and Config
        //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
        query = {
          shortEntity: '#SMC',
          PK: "#SMARTDASH",
          SK: undefined,
          entity:{
            PK:"#SMARTDASH", SK:'#ENT#0ba31685-5071-4eff-bb83-80b0866e9582'
          },
          form: {
            PK:'#SMARTDASH',
            SK:'#SML#be494914-c9a3-4bf3-be39-2cfbd1655c37'
          },
          parent:  undefined,
          attributesStr: JSON.stringify(att) ,
          attributes: att,
          //strAtt: JSON.stringify(this.smartComponents).replace(/\\/gi, ""),
          usersCanEdit: data.usersCanEdit,
          usersCanRead: data.usersCanRead ,
          groupsCanEdit: data.groupsCanEdit,
          groupsCanRead: data.groupsCanRead,
        }
        if(this.record.shortEntity == '#SMC'){
          query.SK = this.record.SK
        }
        //
        console.log(' >> Push Data <<', query);
        //Mutation
        pushData = await API.graphql({
          query: smd_updateSmartLayout, //smd_updateSmartRecord
          variables: { 
            input:query
          },
        });
        pushData = pushData.data.smd_updateSmartLayout
        console.log(' >> Successful update <<' ,pushData);
        response = pushData
        //pushData = JSON.parse(pushData.data)
      } catch (error) {
        popUp(error, 'cach', 'err')
      }
      loading('hidde', 'getItem')
    }
  },
  computed: {
    ...Vuex.mapState([
      "organizationID",
      "userPermissions",
      "permissions",
      "preloadLists",
      "customLayout",
      "customItem",
      'elementList',
      'srcFonts',
      'comboFonts',
    ]),
    //Dragg
    dragOptions() {
      return {
        animation: 200,
        group: "components",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
}
</script>
<style lang="less">
  .hover(){ 
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
  }
  .maker{
    padding: 30px;
    background: #fff;
    &.body{
      .constructor{
        resize: both;
        overflow: auto;
      }
      
    }
  }
  .customPanel{
    .nav-tabs{
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-evenly;
      .nav-item{
        text-align: center;
        width: 100%;
        .nav-link{
          background: #D9D9D9;
          border: none;
          color: #5A5A5A;
          border-radius: 0px;
          font-weight: 600;
          font-size: 14px;
          .hover();
          &.active{ m
            background: #fff;
          }
        }
      }
    }
    .tab-content{
      padding: 20px;
      .tab-pane{
        overflow: auto;
        height: 90vh;
        &.config{
          .list-languages{
            li{
              .hover();
              &.active{
                background: #D9D9D9;
                border: none;
              }
            }
          }
        }
      }
    }
    &.sticky{
      position: fixed;
      width: 31.1%;
      height: 100vh;
      top: 0;
      .hover;
    }
    .gallery{
      height: 100px;
      --height: 100px;
      --width: auto;
      display: flex;
      justify-content: center;
    } 
  }
  .customPanelOption{
    //border: 1px solid #ccc;
    width: 100%;
    padding: 10px 20px;
    font-weight: 600;
    label{
      width: 100%;
    }
    input{
      width: 100%;
    }
    .dropInput{
      float: left;
      width: 40px;
      display:block;
      height: 40px;
      padding: 10px;
      background: #D9D9D9;
      svg{
        width: 20px;
        height: 15px;
        path{
          stroke: #5A5A5A;
        }
      }
    }
    .drop{
      width: 100%;
      padding: 0 0 6px 0;
      overflow: hidden;
      color: #5A5A5A;
      border-bottom: 1px solid #ccc;
      display: block;
      &:hover{
        color: #5A5A5A;
      }
    }
  }
  .menuLink{
    width: 20%;
  }
  .menuTitle{
    text-align: center;
    width: 100%;
  }
  .menuTitle p{
    font-family: var(--font2);
    color: var(--color2);
  }
  .sectionHeader{
    background:  var(--color1);
  }
  
</style>