<template>
  <div class="paper-dash" v-if="gRecord">
    
    <!--Update-->
    <ui-modal name="update" type="update" v-if="gRecord[1] == 'update' || gRecord[1] == 'action' || gRecord[1] == 'new'" >
      <template v-if="record == null" #content>
        <h4>Modal update not found .. </h4>
      </template>
      <template v-else #content>
        <div class="smart-wrap">
          <div class="wrap-title col-md-4">
            <h2  class="title-g">{{record.layoutName}}</h2>
            <p v-if=" record.item.SK == '' || record.item.SK == undefined" class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">{{ record.item.PK}}: (New {{record.item.entityName}})</p>
            <p v-else class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">{{record.item.PK}}: {{ record.item.entityName+ '-'+ record.item.friendlyId}}</p>
            <div v-if="userPermissions.includes('Superadmin') || userPermissions.includes('Admin')" class="collapse" id="infoItem">
              <div class="card card-body">
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;">  <strong>PK: </strong> {{record.item.PK}} </p>
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;">  <strong>SK: </strong> {{record.item.SK}} </p>
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;" v-show=" index != 'strAtt'" v-for="(att, index) in record.item" :key="index">  <strong>{{index+': '}} </strong> <paper-displayer :value="att"/> </p>
              </div>
            </div>
          </div>
          <div class="col wrap-button col-md-8">
            <ui-button :color="'gray'" :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px;'"  :text="'X'" :position="'right'" data-dismiss="modal" />
            <ui-button v-on:click="updateRecord(record, 'Delete');" class="absoluteCenterFlex" :color="'red'" :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px; padding: 12px;'"  :position="'right'" data-dismiss="modal" :text="''">
              <svg width="20" height="20  " viewBox="0 0 78 100" fill="white" >
                <path d="M5.57143 88.8889C5.57143 95 10.5857 100 16.7143 100H61.2857C67.4143 100 72.4286 95 72.4286 88.8889V22.2222H5.57143V88.8889ZM78 5.55556H58.5L52.9286 0H25.0714L19.5 5.55556H0V16.6667H78V5.55556Z" fill="#fff" />
              </svg>
            </ui-button>
            <ui-button v-on:click="cloneRecod(record.item)" class="absoluteCenterFlex" :color="'blue'" :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px; padding: 12px;'" :position="'right'" data-dismiss="modal" :text="''">
              <svg width="20" height="20" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 6V2C20 1.46957 19.7893 0.960859 19.4142 0.585786C19.0391 0.210714 18.5304 0 18 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H6V8C6 7.46957 6.21071 6.96086 6.58579 6.58579C6.96086 6.21071 7.46957 6 8 6H20Z" fill="white"/>
                <path d="M26 8H10C9.46957 8 8.96086 8.21071 8.58579 8.58579C8.21071 8.96086 8 9.46957 8 10V26C8 26.5304 8.21071 27.0391 8.58579 27.4142C8.96086 27.7893 9.46957 28 10 28H26C26.5304 28 27.0391 27.7893 27.4142 27.4142C27.7893 27.0391 28 26.5304 28 26V10C28 9.46957 27.7893 8.96086 27.4142 8.58579C27.0391 8.21071 26.5304 8 26 8ZM24 19H19V24H17V19H12V17H17V12H19V17H24V19Z" fill="white"/>
              </svg>
            </ui-button>
            
            <span>
              <ui-button :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px;'" :color="'green'" :text="'Save and Close'" :position="'right'" :id="'saveClose-'+record.item.SK" data-dismiss="modal"  v-on:click="updateRecord(record,'update');" />
              <ui-button :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px;'" :color="'green'" :text="'Save'" :position="'right'" :id="'save-'+record.item.SK" v-on:click="updateRecord(record,'update'); " >
                &nbsp;<i class='bx bxs-save'></i>
              </ui-button>
              <!--ui-button-- v-if="record.item.attributes.path && record.item.attributes.path.attributeValue && record.item.attributes.path.attributeValue != ''" :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px;'" :color="'blue'" :text="'Publish'" :position="'right'" :id="'save-'+record.item.SK" v-on:click="publishSML()" >
              </!--ui-button-->
            </span>
            <span class="col-md-12" v-if="record.item.PK && record.item.SK && record.item.shortEntity != '#ORG' && record.item.SK.startsWith('QUO')">
              <ui-button v-if="organizationData.app == 'BPE'"  :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px; height: 44px;'" :color="'green'" :text="''" :position="'right'" :href="organizationData.publicAliasURL+'/checkout?data='+record.item.SK.replace('#','%23')" target="_blank">
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" >
                  <path d="M24 7.5V18.75C24 19.9875 23.0182 21 21.8182 21H4.36364C3.76364 21 3.27273 20.4938 3.27273 19.875C3.27273 19.2562 3.76364 18.75 4.36364 18.75H21.8182V7.5C21.8182 6.88125 22.3091 6.375 22.9091 6.375C23.5091 6.375 24 6.88125 24 7.5ZM3.27273 16.5C1.46182 16.5 0 14.9925 0 13.125V6.375C0 4.5075 1.46182 3 3.27273 3H16.3636C18.1745 3 19.6364 4.5075 19.6364 6.375V14.25C19.6364 15.4875 18.6545 16.5 17.4545 16.5H3.27273ZM6.54545 9.75C6.54545 11.6175 8.00727 13.125 9.81818 13.125C11.6291 13.125 13.0909 11.6175 13.0909 9.75C13.0909 7.8825 11.6291 6.375 9.81818 6.375C8.00727 6.375 6.54545 7.8825 6.54545 9.75Z" fill="white"/>
                </svg>
              </ui-button>
              <ui-button v-else  :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px; height: 44px;'" :color="'green'" :text="''" :position="'right'" :href="organizationData.publicAliasURL+'/checkout1?data='+record.item.SK.replace('#','%23')" target="_blank">
                <svg width="30" height="30" viewBox="0 0 24 24" fill="none" >
                  <path d="M24 7.5V18.75C24 19.9875 23.0182 21 21.8182 21H4.36364C3.76364 21 3.27273 20.4938 3.27273 19.875C3.27273 19.2562 3.76364 18.75 4.36364 18.75H21.8182V7.5C21.8182 6.88125 22.3091 6.375 22.9091 6.375C23.5091 6.375 24 6.88125 24 7.5ZM3.27273 16.5C1.46182 16.5 0 14.9925 0 13.125V6.375C0 4.5075 1.46182 3 3.27273 3H16.3636C18.1745 3 19.6364 4.5075 19.6364 6.375V14.25C19.6364 15.4875 18.6545 16.5 17.4545 16.5H3.27273ZM6.54545 9.75C6.54545 11.6175 8.00727 13.125 9.81818 13.125C11.6291 13.125 13.0909 11.6175 13.0909 9.75C13.0909 7.8825 11.6291 6.375 9.81818 6.375C8.00727 6.375 6.54545 7.8825 6.54545 9.75Z" fill="white"/>
                </svg>
              </ui-button>
            </span>
            <ui-button  v-if="record.item.shortEntity == 'INF'" :style="'background:'+organizationData.colorSeriesA[5]+'; margin: 0 0 0 10px;'" :color="'blue'" :text="''" :position="'right'" >
              <router-link  :to=" { paht:record.item.shortEntity.toLowerCase(), name: record.item.shortEntity, query: { SK:record.item.SK, formSK: record.item.form.SK  } }"  target="_blank"  >
                <svg  xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="#fff" fill-rule="evenodd" d="M10 1.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-1Zm-5 0A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5v1A1.5 1.5 0 0 1 9.5 4h-3A1.5 1.5 0 0 1 5 2.5v-1Zm-2 0h1v1A2.5 2.5 0 0 0 6.5 5h3A2.5 2.5 0 0 0 12 2.5v-1h1a2 2 0 0 1 2 2V14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3.5a2 2 0 0 1 2-2Z"/></svg>
              </router-link>
            </ui-button>
            
            <ui-button v-if="record.item.GSP1PK1 && record.item.GSP1PK1.startsWith('CON#')"  :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px; height: 44px;'" :color="'blue'" :text="''" :position="'right'" data-dismiss="modal" data-toggle="modal" data-target="#modal-send"  v-on:click="setgRecord([record.item, 'email'])">
              <svg width="30" height="30" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M34 4.01237C33.8493 3.99588 33.6974 3.99588 33.5466 4.01237H2.58604C2.38761 4.01561 2.19049 4.0472 2 4.10629L17.9779 21L34 4.01237Z" fill="white"/>
                <path d="M35.865 6L19.485 22.674C19.0634 23.1024 18.4932 23.3428 17.8987 23.3428C17.3043 23.3428 16.7341 23.1024 16.3125 22.674L0.0787501 6.12649C0.0288444 6.31398 0.00238352 6.50717 0 6.70146V29.7001C0 30.3101 0.237053 30.8951 0.65901 31.3264C1.08097 31.7577 1.65326 32 2.25 32H33.75C34.3467 32 34.919 31.7577 35.341 31.3264C35.7629 30.8951 36 30.3101 36 29.7001V6.70146C35.991 6.46188 35.9455 6.2252 35.865 6V6ZM3.79125 29.7001H2.2275V28.0557L10.4062 19.7647L11.9925 21.3861L3.79125 29.7001ZM33.7275 29.7001H32.1525L23.9512 21.3861L25.5375 19.7647L33.7162 28.0557L33.7275 29.7001Z" fill="white"/>
              </svg>
            </ui-button>
              <ui-button   v-if="record.item.shortEntity == 'WEB'" :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px; height: 44px;'" :color="'blue'" :text="''" :position="'right'" >
              <router-link  :to=" { path:'/smarpage',query: { PK:record.item.PK, SK:record.item.SK} }"  target="_blank"  >
                <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="white" d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/></svg>
              </router-link>
            </ui-button>
            <el-options-module v-if="dataModule" :data="[{PK:record.item.PK, SK:record.item.SK,}]" :options="dataModule.actions"/>
          </div>
          <p  v-if="userPermissions.includes('Superadmin') || userPermissions.includes('Admin') || userPermissions.includes('Editor') " style="display:block; width:100%; display: flex;  align-items: center;">
            <a class="smart-text t-14 t-strong"  data-toggle="collapse" href="#collapseExampleP" role="button" aria-expanded="false" aria-controls="collapseExampleP">
              Permissons
            </a>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"/></svg>
          </p>
          <div class="collapse" id="collapseExampleP" style="    width: 100%;">
            <div class="card card-body">
              <div class="smart-wrap" style="background:#ccc; padding:20px; width:100%;">
                <div class="col col-md-3" >
                  <div class="check" >
                    <label for="" style="font-weight: 600;">Groups Can Edit</label>
                    <div class="envelop permissions" v-for="(check, zindex) in permissions" :key="zindex">
                      <input type="checkbox" class="box" :value="check.value" v-model="record.item.groupsCanEdit"/>
                      <span class="text" > {{check.value}} </span>
                    </div>
                  </div>
                </div>
                <div class="col col-md-3" >
                  <div class="check" >
                    <label for="" style="font-weight: 600;">Groups Can Read</label>
                    <div class="envelop permissions" v-for="(check, zindex) in permissions" :key="zindex">  
                      <input type="checkbox" class="box" :value="check.value" v-model="record.item.groupsCanRead"/>
                      <span class="text" > {{check.value}} </span>
                    </div>
                  </div>
                </div>
                <div class="col col-md-3" >
                  <div class="check" >
                    <label for="" style="font-weight: 600;">User Can Edit</label>
                    <div class="envelop permissions">
                      <input type="checkbox" class="box" value="@author" v-model="record.item.usersCanEdit"/>
                      <span class="text" > @Author </span>
                    </div>
                    <div class="envelop permissions">     
                      <input type="checkbox" class="box" value="@client" v-model="record.item.usersCanEdit"/>
                      <span class="text" > @Client </span>
                    </div>
                    <div class="envelop permissions" >
                      <input type="checkbox" class="box" value="@responsible" v-model="record.item.usersCanEdit"/>
                      <span class="text" > @Responsible </span>
                    </div>
                  </div>
                </div>
                <div class="col col-md-3" >
                  <div class="check" >
                    <label for="" style="font-weight: 600;">User Can Read</label>
                    <div class="envelop permissions">
                      <input type="checkbox" class="box" value="@author" v-model="record.item.usersCanRead"/>
                      <span class="text" > @Author </span>
                    </div>
                    <div class="envelop permissions">
                      <input type="checkbox" class="box" value="@client" v-model="record.item.usersCanRead"/>
                      <span class="text" > @Client </span>
                    </div>
                    <div class="envelop permissions" >
                      <input type="checkbox" class="box" value="@responsible" v-model="record.item.usersCanRead"/>
                      <span class="text" > @Responsible </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ui-modal name="subscription" type="message" >
            <template #content>
              <div style="width: 500px; margin:0 auto; text-align:center; display:block;" v-if="subData != null">
                <br>
                <h4>{{subData.updatedBy}} has just updated this record.</h4>
                <h6>If you accept changes, your changes will be lost. <br> Accept changes?</h6>
                <br>
                <p> <strong>Changes made:</strong> </p>
                <p v-for="(att, index) in subData.attNew" :key="index" style="margin: 5px 0 0 0;" ><span style="font-weight: 500;">{{att[0]}}: </span>  {{ att[1]}}  </p>
                <ui-button :style="'margin:20px 45px; width: 160px; float:left; background: '+ organizationPalette[1]+';'" :color="'blue'" :text="'Yes'" :position="'center'"  v-on:click="getItem(record.item,'edit'); setRecordSub(false);actionModal('subscription', 'close')"/>
                <ui-button :style="'margin:20px 45px; width: 160px; float:left; background: '+ organizationPalette[1]+';'" :color="'gray'" :text="'No'" :position="'center'" v-on:click="actionModal('subscription', 'close')" />
              </div>
            </template>
          </ui-modal>
        </div>
        <div class="smart-wrap ">
          <div v-if="entities[record.item.shortEntity].allowSelectPK == true && userPermissions.includes('Superadmin') && !record.item.SK" class="input-wrap col-md-5">
            <label for="">PK </label>
            <select name="" id="" v-model="record.item.PK">
              <option selected disabled hidden>select an option</option>
              <option value="#SMARTDASH">System</option>               m
              <option :value="item.value" v-for="(item, index) in preloadLists['#ORG']" :key="index">
                {{ item.value.replace("ORG#", "") }}
              </option>               
            </select>
          </div>
          <div v-if="record.layoutCategory == 'smartForm' && record.item.shortEntity == 'INF'" class="input-wrap">
            <label for="">Filter Smart Form </label>
            <select name="" id="" v-model="filterSmarForm">
              <option value="complete"> Complete</option>
              <option value="incomplete">Incomplete</option>
              <option value="all">All</option>
            </select>
          </div>

          <paper-layout ref="layout" :layoutCategory="record.layoutCategory" :filterSmarForm="filterSmarForm" id="layout" :layout="record.layout" :data="{PK:record.item.PK,SK:record.item.SK}" :models="record.item.attributes" :parent="organizationID"/>

          
        </div>
        <PaperRenderDetail v-if="render == true" :data='record.item' />
        <PaperRenderSML v-if="render == true && record.item.attributes.parentSML"  :data="record.item.attributes.parentSML.attributeValue" />
      </template>
    </ui-modal>
    <!--Update-->
    <ui-modal style="padding: 50px;" name="updateB" type="update" v-if="false">
      <template v-if="recordB == null" #content>
        <h4>Modal <h4>Modal update not found .. </h4> update not found .. </h4>
      </template>
      <template v-else #content>
        <div class="smart-wrap">
          <div class="wrap-title col-md-4">
            <h2  class="title-g">{{recordB.layoutName}}</h2>
            <p v-if=" recordB.item.SK == '' || recordB.item.SK == undefined" class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">{{ recordB.item.PK}}: (New {{recordB.item.entityName}})</p>
            <p v-else class="infoItem" data-toggle="collapse" href="#infoItem" role="button" aria-expanded="false" aria-controls="infoItem">{{recordB.item.PK}}: {{ recordB.item.entityName+ '-'+ recordB.item.friendlyId}}</p>
            <div v-if="userPermissions.includes('Superadmin') || userPermissions.includes('Admin')" class="collapse" id="infoItem">
              <div class="card card-body">
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;">  <strong>PK: </strong> {{recordB.item.PK}} </p>
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;">  <strong>SK: </strong> {{recordB.item.SK}} </p>
                <p style="color: #949494; margin:0; font-size: 12px; padding: 0;" v-show=" index != 'strAtt'" v-for="(att, index) in recordB.item" :key="index">  <strong>{{index+': '}} </strong> <paper-displayer :value="att"/> </p>
              </div>
            </div>
          </div>
          <div class="col wrap-button col-md-8">
            <ui-button :color="'gray'" :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px;'"  :text="'X'" :position="'right'" data-dismiss="modal" />
            <span>
              <ui-button :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px;'" :color="'green'" :text="'Save and Close'" :position="'right'" :id="'saveClose-'+recordB.item.SK" data-dismiss="modal"  v-on:click="updateRecord(recordB,'update', true);recordB=null" />
              <ui-button v-if="recordB.item.attributes.path && recordB.item.attributes.path.attributeValue && recordB.item.attributes.path.attributeValue != ''" :style="'background:'+organizationPalette[1]+'; margin: 0 0 0 10px;'" :color="'blue'" :text="'Publish'" :position="'right'" :id="'save-'+recordB.item.SK" v-on:click="publishSML()" >
              </ui-button>
            </span>

          </div>
          <p  v-if="userPermissions.includes('Superadmin') || userPermissions.includes('Admin') || userPermissions.includes('Editor') " style="display:block; width:100%; display: flex;  align-items: center;">
            <a class="smart-text t-14 t-strong"  data-toggle="collapse" href="#collapseExampleP" role="button" aria-expanded="false" aria-controls="collapseExampleP">
              Permissons
            </a>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1024 1024"><path fill="currentColor" d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"/></svg>
          </p>
          <div class="collapse" id="collapseExampleP" style="    width: 100%;">
            <div class="card card-body">
              <div class="smart-wrap" style="background:#ccc; padding:20px; width:100%;">
                <div class="col col-md-3" >
                  <div class="check" >
                    <label for="" style="font-weight: 600;">Groups Can Edit</label>
                    <div class="envelop permissions" v-for="(check, zindex) in permissions" :key="zindex">
                      <input type="checkbox" class="box" :value="check.value" v-model="recordB.item.groupsCanEdit"/>
                      <span class="text" > {{check.value}} </span>
                    </div>
                  </div>
                </div>
                <div class="col col-md-3" >
                  <div class="check" >
                    <label for="" style="font-weight: 600;">Groups Can Read</label>
                    <div class="envelop permissions" v-for="(check, zindex) in permissions" :key="zindex">  
                      <input type="checkbox" class="box" :value="check.value" v-model="recordB.item.groupsCanRead"/>
                      <span class="text" > {{check.value}} </span>
                    </div>
                  </div>
                </div>
                <div class="col col-md-3" >
                  <div class="check" >
                    <label for="" style="font-weight: 600;">User Can Edit</label>
                    <div class="envelop permissions">
                      <input type="checkbox" class="box" value="@author" v-model="recordB.item.usersCanEdit"/>
                      <span class="text" > @Author </span>
                    </div>
                    <div class="envelop permissions">     
                      <input type="checkbox" class="box" value="@client" v-model="recordB.item.usersCanEdit"/>
                      <span class="text" > @Client </span>
                    </div>
                    <div class="envelop permissions" >
                      <input type="checkbox" class="box" value="@responsible" v-model="recordB.item.usersCanEdit"/>
                      <span class="text" > @Responsible </span>
                    </div>
                  </div>
                </div>
                <div class="col col-md-3" >
                  <div class="check" >
                    <label for="" style="font-weight: 600;">User Can Read</label>
                    <div class="envelop permissions">
                      <input type="checkbox" class="box" value="@author" v-model="recordB.item.usersCanRead"/>
                      <span class="text" > @Author </span>
                    </div>
                    <div class="envelop permissions">
                      <input type="checkbox" class="box" value="@client" v-model="recordB.item.usersCanRead"/>
                      <span class="text" > @Client </span>
                    </div>
                    <div class="envelop permissions" >
                      <input type="checkbox" class="box" value="@responsible" v-model="recordB.item.usersCanRead"/>
                      <span class="text" > @Responsible </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ui-modal name="subscription" type="message" >
            <template #content>
              <div style="width: 500px; margin:0 auto; text-align:center; display:block;" v-if="subData != null">
                <br>
                <h4>{{subData.updatedBy}} has just updated this recordB.</h4>
                <h6>If you accept changes, your changes will be lost. <br> Accept changes?</h6>
                <br>
                <p> <strong>Changes made:</strong> </p>
                <p v-for="(att, index) in subData.attNew" :key="index" style="margin: 5px 0 0 0;" ><span style="font-weight: 500;">{{att[0]}}: </span>  {{ att[1]}}  </p>
                <ui-button :style="'margin:20px 45px; width: 160px; float:left; background: '+ organizationPalette[1]+';'" :color="'blue'" :text="'Yes'" :position="'center'"  v-on:click="getItem(recordB.item,'edit'); setRecordSub(false); actionModal('subscription', 'close')"/>
                <ui-button :style="'margin:20px 45px; width: 160px; float:left; background: '+ organizationPalette[1]+';'" :color="'gray'" :text="'No'" :position="'center'" v-on:click="actionModal('subscription', 'close')" />
              </div>
            </template>
          </ui-modal>
        </div>
        <div class="smart-wrap ">
          <div v-if="entities[recordB.item.shortEntity].allowSelectPK == true && userPermissions.includes('Superadmin') && !recordB.item.SK" class="input-wrap col-md-5">
            <label for="">PK </label>
            <select name="" id="" v-model="recordB.item.PK">
              <option selected disabled hidden>select an option</option>
              <option value="#SMARTDASH">System</option>               m
              <option :value="item.value" v-for="(item, index) in preloadLists['#ORG']" :key="index">
                {{ item.value.replace("ORG#", "") }}
              </option>               
            </select>
          </div>
          <div v-if="recordB.layoutCategory == 'smartForm' && recordB.item.shortEntity == 'INF'" class="input-wrap">
            <label for="">Filter Smart Form </label>
            <select name="" id="" v-model="filterSmarForm">
              <option value="complete"> Complete</option>
              <option value="incomplete">Incomplete</option>
              <option value="all">All</option>
            </select>
          </div>

          <paper-layout :layoutCategory="recordB.layoutCategory" :filterSmarForm="filterSmarForm" id="layout" :layout="recordB.layout" :data="{PK:recordB.item.PK,SK:recordB.item.SK}" :models="recordB.item.attributes" :parent="organizationID"/>

          
        </div>
      </template>
    </ui-modal>
    <!--Set Status-->
    <ui-modal name="status" type="status" v-if="gRecord[1] == 'status'" >
      <template v-if="record == null" #content>
        <h4>Modal status not found .. </h4>
      </template>
      <template v-else #content>
        <div class="row">
          <div class="col wrap-title">
            <h4 class="title-g">
              {{ record.title }} ?
            </h4>
          </div>
          <div class="col wrap-button">
            <ui-button style="margin: 0 0 0 10px;" :color="'red'" :text="'Cancel'" :position="'right'" data-dismiss="modal"/>
            <ui-button style="margin: 0 0 0 10px;" :color="'green'" :text="'Confirm'" :position="'right'" data-dismiss="modal"  v-on:click="updateRecord(record, record.action);"/>
          </div>
        </div>
      </template>
    </ui-modal>
    <!--Email-->
    <ui-modal name="tmpEmail" type="email" v-if="gRecord[1] == 'email'">
      <template v-if="record == null" #content>
        <h4>Something went wrong :C  </h4>
      </template>
      <template v-else #content>
        <div class="wrap-head">
          <div class="row">
            <div class="col wrap-title">
              <h4 class="title-g">
                Send Email
              </h4>
              <span><strong>PK: </strong>{{record.item.PK}}</span><br>
              <span><strong>SK: </strong>{{record.item.SK}}</span>
            </div>
            <div class="col wrap-button">
              <ui-button style="margin: 0 0 0 10px;" :color="'red'" :text="'Cancel'" :position="'right'" data-dismiss="modal"/>
              <ui-button style="margin: 0 0 0 10px;" :color="'green'" :text="'Send'" :position="'right'" data-dismiss="modal" v-on:click="emailAction('send')"/>
            </div>
          </div>
        </div>
        <br>Editor
        <div class="wrap-content row">
          <div class="col-md-3">
            <h6>Select Template</h6>
            <div class="smart-wrap">
              <div class="input-wrap">
                <label for="">Search Template</label>
                <input type="text" id="search-emailTemplates" v-on:keyup="searchList('search-emailTemplates', 'emailTemplates' )">
              </div>
            </div>
            <div class="list-group" id="emailTemplates">
              <a style="cursor:pointer;" v-on:click="emailAction('set', emailTemplate) " class="list-group-item list-group-item-action" v-for="(emailTemplate, eindex) in emailTemplates" :key="eindex">
                {{emailTemplate.attributes['emailTemplate.name'].value}}
              </a>
            </div>
          </div>
          <div class="list-group col-md-9">
            <div class="smart-wrap">
                <div class="input-wrap col-12">
                  <label for="">To</label>
                  <input class="form-control" type="email" id="myInput"  title="Type in a name"  v-model="toEmail" />
                </div>
                <div class="input-wrap col-12">
                  <label for="">Subject</label>
                  <input class="form-control" type="email" id="myInput"  title="Type in a name" v-model="subjectEmail"  />
                </div>
                <div class="col-12" style="margin: 20px 0;">
                  {{setDataPlEditor('modal-email', emailTemplate)}}
                  <pl-editor name="modal-email" >
                    <template #save>
                      <span style="" class="editor-btn icon" data-action="save" title="Save" :id="'pl-save-modal-email'" v-on:click="saveDataPlEditor('modal-email', emailTemplate)" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#2b2b2b" d="M21 7v12q0 .825-.587 1.413Q19.825 21 19 21H5q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h12Zm-9 11q1.25 0 2.125-.875T15 15q0-1.25-.875-2.125T12 12q-1.25 0-2.125.875T9 15q0 1.25.875 2.125T12 18Zm-6-8h9V6H6Z"/></svg>
                      </span>
                    </template>
                  </pl-editor>
                </div>
              <div class="row form-row" style="margin: 0 0 10px 0!important;">  
              </div>
            </div>
          </div>
        </div>
      </template>
    </ui-modal>
    <!--Modal Sms-->
    <ui-modal name="sms" type="sms" classModal="modal-dialog-centered" v-if="gRecord[1] == 'sms'">
      <template v-if="dataSMS == null" #content>
        <h4>Something went wrong in sms modal  :C</h4>
      </template>
      <template v-else #content>
        <div class="wrap-head">
          <div class="row">
            <div class="col wrap-title">
              <h4 class="title-g"> Send SMS </h4>
            </div>
            <div class="col wrap-button">
              <ui-button style="margin: 0 0 0 10px;" :color="'red'" :text="'Cancel'" :position="'right'" data-dismiss="modal"/>
              <ui-button style="margin: 0 0 0 10px;" :color="'green'" :text="'Send'" :position="'right'" data-dismiss="modal" v-on:click="msmActions('send', dataSMS)"/>
            </div>
          </div>
        </div>
        <div class="smart-wrap" >
          <div class="input-wrap col-12" v-if="dataSMS.options && dataSMS.options.length != 0">
            <label  for="">Options</label>
            <div class="check-wrap" style="margin:20px 0 0 0;">
              <div class="envelop"  v-for="(option, zindex) in dataSMS.options" :key="zindex">
                <input type="radio" class="check input" :value="'PHO#'+option" v-model="dataSMS.phone" /> 
                <span class="check text" > {{ formatData('phone',option)}} </span>
              </div>
            </div>
          </div>
          <div v-else>
            No associated phone found
          </div>
          <div class="input-wrap col-12">
            <label for="">Message</label>
            <textarea name="" id="" cols="30" rows="10" v-model="dataSMS.message"></textarea>
          </div>
        </div>
      </template>
    </ui-modal>
    <!--Modal custom-->
    <ui-modal name="custom" type="update" classModal="modal-dialog-centered modalAll" v-if="location != '/dashboard' && location !='/webpreview'">
      <template #content  v-if="record == null" >
      </template>
      <template #content v-else>
        <div class="smart-wrap modal-header" style="margin: 0 0 20px 0;">
          <div class="smart-wrap " style="display: flex; justify-content: space-between;">
            <h1></h1>
            <ui-button data-dismiss="modal" text="X"/>
          </div>
        </div>
        <img style=" background: #ccc; border-radius: 300px; width: 70px; height: 70px; margin: 0 10px 0 0;" :src="record.item.attributes.driver.picture" alt="">
        <h4>{{ record.item.attributes.driver.attributeValue.value }}</h4>
        <div class="smart-wrap">
          <div class="input-wrap col-md-6" v-for="(input, x) in record.item.attributes" :key="x">
            <label for="">{{ input.label }}</label>
            <span class="inputMimo" v-if="input.attributeType == 'L'" >{{ input.attributeValue.value }}</span>
            <span class="inputMimo" v-else >{{input.attributeValue }}</span>
          </div>
        </div>
      </template>
    </ui-modal>
  </div> 
</template>
<script>
//AWS - GRAPHQL
import {  smd_getRecord, smd_getDashDataYear,smd_getDashDataContact, smd_generateGraphicPost, smd_getSMLItem,smd_listEntityObj, smd_composeEmailTemplate } from '../../graphql/queries'
import { smd_updateSmartRecord,smd_setItemState, smd_sendEmail2,smd_sendSMS, smd_sendAndSaveEmail } from '../../graphql/mutations'
import { onUpdateSmartRecord, onUpdateAttributes  } from '../../graphql/subscriptions'
//Tools
import Vuex from 'vuex'
import { API } from 'aws-amplify'
import Swal from 'sweetalert2'
import { formatData, loading, popUp, searchList, processLayout } from '../../store/tools.js';
//import { v4 as uuidv4 } from 'uuid';
//Components
import PaperTable from './PaperTable.vue'
import PaperTableView from './PaperTableView.vue'
import PaperSmart from './PaperSmart.vue'
import PaperHistory from './PaperHistory.vue'
import PaperLayout from './PaperLayout.vue'
import PaperDisplayer from './PaperDisplayer.vue'
import PaperRecord from './PaperRecord.vue'
import PaperConstructor from './PaperConstructor.vue'
import PaperRenderDetail from './PaperRenderDetail.vue'
import PaperRenderSML from './PaperRenderSML.vue'
import UiButton from '../ui/UiButton.vue'
import UiModal from '../ui/UiModal.vue'
import PlEditor from '../../assets/plugins/PlEditor.vue'
import ElOptionsModule from '../element/ElOptionsModule.vue'
import trumbowyg  from 'trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css';
//import $ from "jquery";
//import html2pdf from 'html2pdf'
export default {
  name: "paper-modal",
  components:{
    PaperTable,
    PaperSmart,
    PaperHistory,
    PaperTableView,
    UiButton,
    PaperLayout,
    PaperDisplayer,
    PaperRecord,
    PaperConstructor,
    UiModal,
    PlEditor,
    PaperRenderDetail,
    PaperRenderSML,
    ElOptionsModule
  },
  data() {
    return {
      dataSMS: null,
      data: null, 
      layout:[],
      dataTable: undefined,
      showVersions: false,
      layoutFormats: {
        none: ' ',
        draft:'width: 1000px; height:1000px;', 
        twiterPost:'width: 1012px; height:506px;', 
        twiterHeader: 'width: 1500px; height:500px;', 
        facebookPost:'width: 1200px; height:630px;', 
        instagramPost :'width: 1080px; height:1080px;', 
        instagramStory:'width: 1000px; height:1920px;', 
        dribbbleShot:'width: 400px; height:300px;',
        linkedinCover:'width: 1584px; height:396px;',
      },

      //DATA
      record: null,
      recordB: null,
      contact: null,
      subData: null,
      //render
      render: false,

      //Emails
      toEmail: null,
      subjectEmail: null,
      emailTemplates: null,
      emailTemplate: null,
      dataEditor: null,

      //filterSmarForm
      filterSmarForm: 'all',
      layoutCategory: '',
      activeStatus: '1',
      filterEntity: 'all',
      tableEntities: [],
      v_search:'',
      //Wrap Custom mobil
      inputs: [
        {
          inputType:'text',
          attributeName:'firtName',
          label:'Hola'
        },
        {
          inputType:'text',
          attributeName:'firtName',
          label:'Hola'
        },
        {
          inputType:'text',
          attributeName:'firtName',
          label:'Hola'
        },
      ],
      //location
      location: ''
    }
  },
  created() {
    this.location = location.pathname
    console.log(this.gRecord);
    this.subscribe()
  },
  methods: {
    //Vuex
    ...Vuex.mapMutations(["setDataUser","setRecord","setgRecord", "setRecordSub",'setNotifications']),
    ...Vuex.mapActions(["setFormat","getRecord","cloneRecod","deleteRecord"]),
    styleModal(modal){
      console.log(Math.floor($('#modal-update').scrollTop()));
      document.getElementById(modal).style.paddingTop = Math.floor($('#modal-update').scrollTop())+'px'
      console.log(document.getElementById(modal).style.paddingTop);
    },
    actionSave(){
      if (this.$router.currentRoute.value.query.name == 'clietDash') {
        let forceUpdate = document.getElementById('forceUpdate')
        console.log(forceUpdate);
        var event = new Event('click');
        forceUpdate.dispatchEvent(event);
      }
    },
    //Sub
    saveDataPlEditor(id,att){
      this.emailTemplate = document.getElementById('pl-editor-'+id).innerHTML
      document.getElementById('pl-save-modal-email').classList.remove('save-btn')
    },
    setDataPlEditor(id,att){
      setTimeout(() => {
        document.getElementById('pl-editor-'+id).innerHTML = this.emailTemplate
      }, 500);
    },
    subscribe(){
      API.graphql({ 
        query: onUpdateAttributes,
        }).subscribe({
        next: (eventData) => {
          this.subData = null
          let subData = eventData.value.data.onUpdateAttributes;
          subData = JSON.parse(subData.data)
          subData.attNew = []
          if (this.record) {
            let focus = this.record.item
            //console.log('Data of Sub:', subData);
            //console.log('Focus Iten', focus);
            if (subData.PK == this.organizationID ) {
              //Sub in record
              if(subData.SK == focus.SK) {
                if (subData.updatedBy != this.user) {
                  for (const key in subData.attributes) {
                    try {
                      if (JSON.stringify(subData.attributes[key].attributeValue) != JSON.stringify(focus.attributes[key].attributeValue)) {
                        if (typeof subData.attributes[key].attributeValue != 'string') {
                          subData.attNew.push([subData.attributes[key].label, 'New Item'])
                        } else{
                          subData.attNew.push([subData.attributes[key].label, subData.attributes[key].attributeValue])
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  $('#modal-subscription').modal('show')
                  this.styleModal('subscription')
                }
              }
              this.subData = subData
              /*
              //Notifications
              if (pullData.smd_response.notifyme == true) {
                this.checkPreloadList(this.menu)
              }
              //Alerts
              if (pullData.smd_response.popAlert == true) {
                popUp(false,pullData.smd_response,data)
              }*/
            }
          }
        }
      });
      API.graphql({ 
        query: onUpdateSmartRecord,
        }).subscribe({
        next: (eventData) => {
          this.subData = null
          let subData = eventData.value.data.onUpdateSmartRecord;
          subData = JSON.parse(subData.data)
          subData.attNew = []
          if (this.record) {
            let focus = this.record.item
            //console.log('Data of Sub:', subData);
            //console.log('Focus Iten', focus);
            if (subData.PK == this.organizationID ) {
              //Sub in record
              if(subData.SK == focus.SK) {
                if (subData.updatedBy != this.user) {
                  for (const key in subData.attributes) {
                    try {
                      if (JSON.stringify(subData.attributes[key].attributeValue) != JSON.stringify(focus.attributes[key].attributeValue)) {
                        if (typeof subData.attributes[key].attributeValue != 'string') {
                          subData.attNew.push([subData.attributes[key].label, 'New Item'])
                        } else{
                          subData.attNew.push([subData.attributes[key].label, subData.attributes[key].attributeValue])
                        }
                      }
                    } catch (error) {
                      console.log(error);
                    }
                  }
                  $('#modal-subscription').modal('show')
                  this.styleModal('subscription')
                }
              }
              this.subData = subData
              /*
              //Notifications
              if (pullData.smd_response.notifyme == true) {
                this.checkPreloadList(this.menu)
              }
              //Alerts
              if (pullData.smd_response.popAlert == true) {
                popUp(false,pullData.smd_response,data)
              }*/
            }
          }
        }
      });
    },
    //Tools
    formatData(format, data){
      return formatData(format, data)
    },
    checkAttribute(data){
      return data
      if (!this.dataTable[0].hasOwnProperty(data.attributeName)) {
      }
    },
    searchList(input, list){
      searchList(input, list);
    },
    //publishSML
    publishSML(){
      loading('show','up')
      let data = this.record.item.attributes.parentSML.attributeValue
      this.render = true
      let url = this.$router.resolve( { path:'/smartmaker',query: { name:'SMLUP', PK:data.PK, SK:data.SK, publish: true } });
      //window.open(url.href, '_blank')
      setTimeout(() => {
        
        this.render = false
      }, 2000);
    },
    //Functions
    async emailAction(action, tmp, item){
      console.log(action);
      switch (action) {
        case 'list':
          try {
            this.toEmail = ""
            this.subjectEmail=""
            const wo_PullItem = await API.graphql({
              query: smd_listEntityObj,
              variables: {
                active: '1' ,
                PK: this.organizationID,
                shortEntity: 'EMT',
                pageSize: 0 ,
              },
            });
            console.log(JSON.parse(wo_PullItem.data.smd_listEntityObj.data));
            this.emailTemplates = JSON.parse(wo_PullItem.data.smd_listEntityObj.data)
          } catch (error) {
            console.log(error);
          }
        break;
        case 'set':
          try {              
            let config={
              emailTPK: this.organizationID,
              emailTSK: tmp.SK,
              itemPK: this.organizationID,
              itemSK: this.record.item.SK,
              itemGSP1PK1: this.record.item.GSP1PK1,
              itemGSP1SK1: this.record.item.GSP1SK1,
              baseURL: window.location.origin, 
            }
            console.log(config);
            let pullData = await API.graphql({
              query: smd_composeEmailTemplate,
              variables: config
            });
            pullData = pullData.data.smd_composeEmailTemplate
            this.toEmail = ''
            this.toEmail = JSON.parse(pullData.data).emails
            this.subjectEmail = JSON.parse(pullData.data).subject
            this.emailTemplate = JSON.parse(pullData.data).body
            this.setDataPlEditor('modal-email')
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        break;
        case 'send':
          try {              
            let push = ''
            let config={
              orgPK: this.organizationID,
              GSP1PK1: this.record.item.GSP1PK1,
              to: this.toEmail,
              subject: this.subjectEmail,
              bodyHTML: this.emailTemplate
            }
            console.log(config);
            push = await API.graphql({
              query: smd_sendAndSaveEmail, //smd_updateSmartRecord
              variables: config
            });
            console.log('eviado exitosament');
            console.log(push);
            //smd_sendEmail2(bodyHTML: "hello hello", to: "marcelo@bizplaneasy.comm", subject: "hola from lambda")
            Swal.fire({
              icon: 'success',
              title: 'Mail sent successfully' ,
              //text: JSON.stringify(error),
            })
          } catch (error) {
            console.log(error);
            Swal.fire({
              icon: 'error',
              title: 'error' ,
              text: JSON.stringify(error),
            })
          }
        break;
        case 'editor':
          try {       
            this.itemPK = PK
            this.itemSK = SK
            setTimeout(() => {      
              this.setEditor(SK)
            }, 1000);
          } catch (error) {
            console.log(error);
          }
        break;
      }
    },
    setDataEditor2(id, data, action){
      switch (action) {
        case 'init':
          setTimeout(() => {
            document.getElementById(id).innerHTML = data
          }, 100);
        break;
        case 'edit':
          setTimeout(() => {
            this.emailTemplate = document.getElementById(id).innerHTML 
          }, 100);
        break;
      }
    },
    async msmActions(action, data){
      switch (action) {
        case 'send':
          let push = ''
          let config = {}
          try {     
            config={
              phonePK: data.PK,
              phoneSK: data.phone,
              message: data.message,
              contactSK: data.contact
            }
            console.log(config);
            push = await API.graphql({
              query: smd_sendSMS, //smd_updateSmartRecord
              variables: config
            });
            console.log('eviado exitosament');
            console.log(push);
            //smd_sendEmail2(bodyHTML: "hello hello", to: "marcelo@bizplaneasy.comm", subject: "hola from lambda")
            Swal.fire({
              icon: 'success',
              title: 'SMS sent successfully' ,
              //text: JSON.stringify(error),
            })
          } catch (error) {
            console.log(error);
            Swal.fire({
              icon: 'error',
              title: 'error' ,
              text: JSON.stringify(error),
            })
          }
        break;
      }
    },
    //Record handling
    async getItem( data, mode, actionItem, second  ) { 
      try {
        this.actionModal('subscription', 'close')
      if (actionItem && actionItem == 'stay') {
        //console.log([data, mode, actionItem, second],'\n only cache---------');
        return
      }
      //console.clear()
      loading('show', 'getItem')
      this.setRecordSub(false)
      if (second == true) {
        console.log('hola');
          this.recordB = null
          //return
          this.$forceUpdate();
        }else{
          //this.record = null
        }
      let pullData = null
      let action = null
      let entity = null
      //Web Edit 
      let groupRecord = undefined
      console.log('>> Data Item to Update:\n '+mode,data);
      //console.log('>> Mode: ',mode);
      if (this.pageQuery == '//#SML//' && location.pathname != "/webpreview" && data.shortEntity != '#SMC' ) {
        loading('hidde', 'getItem')
        return
      }
      let query = {}
      console.log(data);
      try {
        query = {
          itemPK: data.PK,
          itemSK: data.SK,
          SMLPK: data.form.PK,
          SMLSK: data.form.SK,
          parent: data.parent,
          entity: data.entity
        }
      } catch (error) {
        console.log(error);
      }
        switch (mode) {
          case 'action':
            console.log(actionItem);
            action = JSON.parse(this.entities[data.shortEntity]['SML.descendantEntitiesWithForm'])[actionItem]
            //console.log('This action for item:',action);
            query.SMLPK = action['modules.formSK'].PK
            query.SMLSK = action['modules.formSK'].SK
            query.entity = {
              PK: action['metaEntity.descendantEntities'].PK ,
              SK: action['metaEntity.descendantEntities'].SK 
            },
            query.parent = {
              PK: data.PK ,
              SK: data.SK,
            }
            query.itemPK= undefined
            query.itemSK= undefined
            //Check entity for record
            for (const key in this.entities) {
              //console.log(key);
              if ( action['metaEntity.descendantEntities'].id == key || action['metaEntity.descendantEntities'].id == this.entities[key].SK ) {
                entity = this.entities[key]
                console.log(entity);
              }
            }
            if (!entity) {
              Swal.fire({
                icon: 'info',
                title: 'entity not found' ,
              })
            }
          break;
          case 'email':
            console.clear()
            this.record ={}
            this.record.item = data
            console.log(this.record);
            this.emailAction('list')
            $('#modal-tmpEmail').modal('show')
            loading('hidde', 'getItem || set email')
            return
          break;
          case 'sms':
            this.record = null
            console.clear()
            console.log(data);
            this.dataSMS ={}
            this.dataSMS.options = []
            this.dataSMS.message = ''
            this.dataSMS.PK = data.PK
            let options = []
            switch (data.shortEntity) {
              case "CON":
                this.dataSMS.contact = data.SK
                for (let index = 0; index < data.phones.length; index++) {
                  options.push(data.phones[index].phoneNumber)
                }
              break;
              case "PHO":
                options.push(data.SK.replace('PHO#',''))
              break;
              case "PHC":
                this.dataSMS.contact = data.GSP1PK1
                options.push(data.phoneNumber)
              break;
            }
            this.dataSMS.options = options
            this.dataSMS.phone = 'PHO#'+options[0]
            console.log(this.dataSMS);
            $('#modal-sms').modal('show')
            loading('hidde', 'getItem || set email')
            return
          break;
          case 'noty':
            query.itemPK= data.itemPK,
            query.itemSK= data.itemSK,
            query.SMLPK = data.itemForm.PK
            query.SMLSK = data.itemForm.SK
            query.entity = data.itemEntity
            query.parent = undefined
          break;
          case 'out':
            console.log(data);
            this.record = {}
            this.record.item = data
            loading('hidde', 'getItem || set out')
            return
          break;
          case 'status':
            try {
              this.record = {}
              this.record.item = data
              this.record.title = actionItem + ' item '+ data.entityName + " - " + data.friendlyId
              this.record.action = actionItem
              $('#modal-status').modal('show')
              loading('hidde', 'getItem || set out')
            } catch (error) {
              console.log(error);
            }
            return
          break;
          case 'update':
            
            if (!$('#modal-update')[0]) {
              setTimeout(() => {
                $('#modal-update').modal('show')
              }, 800);
            }else{
              $('#modal-update').modal('show')
            }
          break;
          case 'new':
            if (second == true) {
              this.recordB = pullData
            }else{
              this.record = pullData
            }
            if (data.SK != "ADD") {
              query.SMLPK = data['modules.formSK'].PK
              query.SMLSK = data['modules.formSK'].SK
              query.entity = {
                PK: data['modules.mainEntity'].PK ,
                SK: data['modules.mainEntity'].SK 
              }
            }
            else{
              if (data.shortEntity == 'WEB') {
                groupRecord = data.group.attributeValue
              }
            }
            delete query.itemSK
            console.log('holi', $('#modal-update'));
            if (!$('#modal-update')[0]) {
              setTimeout(() => {
                $('#modal-update').modal('show')
              }, 800);
            }else{
              $('#modal-update').modal('show')
            }
            //query.itemSK= undefined
            //query.itemPK= undefined
          break;
        } 
        console.log('>> Query to Update;',query);
        pullData = await API.graphql({
          query: smd_getSMLItem,
          variables: query
        });
        
        pullData = pullData.data.smd_getSMLItem
        //Response
        if (pullData.smd_response.statusCode != 200) {
          popUp(pullData.smd_response, 'response', 'err')
          loading('hidde', 'getItem')
          return
        }
        else if (pullData.smd_response.popAlert == true) {
          popUp(pullData.smd_response, 'response', 'scc')
        }
        //Proccess Data
        pullData.layout =  JSON.parse(pullData.data)
        
        pullData.item =  JSON.parse(pullData.item)
        if (mode == 'action') {
          console.log(entity);
          pullData.item.shortEntity = entity['metaEntity.metaShortEntity']
          pullData.item.entity = query.entity
          pullData.item.entityName = entity['metaEntity.name']
          pullData.item.parent = {
            PK: data.PK,
            SK: data.SK,
          }
          pullData.item.form = {
            PK:  query.SMLPK,
            SK:  query.SMLSK,
          }
        }
        if (pullData.item.shortEntity == 'INF') {
          for (const key in pullData.item.attributes) {
            if (!pullData.item.attributes[key].hasOwnProperty('complete')) {
              pullData.item.attributes[key].complete = false
            }
          }
        }
        pullData.attStr =  JSON.stringify(pullData.item.attributes)
        
        delete pullData.data;
        if (!pullData.item.PK) {
          pullData.item.PK = this.organizationID
        } 
        if (mode == 'new') {
          if (data.SK != "ADD"){
            pullData.item.PK = this.organizationID
            pullData.item.entity = {
              PK: data['modules.mainEntity'].PK,
              SK: data['modules.mainEntity'].SK,
            };
            pullData.item.form = {
              PK: data['modules.formSK'].PK,
              SK: data['modules.formSK'].SK,
            };
            pullData.item.shortEntity = data['modules.dataQuery'];
          }else{
            pullData.item.entity = data.entity
            pullData.item.form = data.form
            pullData.item.shortEntity = data.shortEntity
            if (groupRecord) {
              pullData.item.attributes.group.attributeValue = groupRecord
            }
          }
          pullData.item.usersCanEdit = [];
          pullData.item.usersCanRead = [ ];
          pullData.item.groupsCanEdit = [ "Admin", "Editor","Superadmin"].concat(this.userPermissions);
          pullData.item.groupsCanRead = [ "Admin", "Editor","Superadmin"].concat(this.userPermissions);
          pullData.item.groupsCanEdit = pullData.item.groupsCanEdit.concat(this.moduleLayout.groupCanEdit);
          pullData.item.groupsCanRead = pullData.item.groupsCanRead.concat(this.moduleLayout.groupCanRead);
        }
        if (pullData.layoutCategory = "smartForm") {
          pullData.layout = processLayout(pullData.layout,'setOrder')
        }
        if( pullData.item.attributes['responsible'] && pullData.item.attributes['responsible'].attributeValue && (pullData.item.attributes['responsible'].attributeValue.id == this.user)){
          pullData.layout = processLayout(pullData.layout,'checkResponsible', this.user)
        } 
        //if (this.organizationID == 'ORG#SMARTDASH') { // ORG#SMARTDASH DIFERENT #SMARTDASH (SYSTEM)
        //   console.log(this.organizationID)
        //    pullData.item.PK = '#SMARTDASH'
        //  }
        console.log("-->> Record to Update: ", pullData);
        
        if (second == true) {
          this.recordB = pullData
        }else{
          this.record = pullData
        }
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      this.$forceUpdate();
      loading('hidde', 'getItem')
      //this.setgRecord(null)
    },
    async updateRecord(data, mode, second) {  
      //console.clear();
      loading('show', 'updateRecord')
      //console.log('>> Data Record to '+ mode+': ',data);
      let query = { }
      let pushData = ''
      let response = ''
      switch (mode) {
        case 'update':
          try {
            //Set Mode and Config
            //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
            query = {
              shortEntity: data.item.shortEntity,
              PK: data.item.PK,
              SK: data.item.SK,
              attributesStr: JSON.stringify(data.item.attributes) ,
              entity: data.item.entity,
              form: data.item.form,
              parent:  data.item.parent,
              usersCanEdit: data.item.usersCanEdit,
              usersCanRead: data.item.usersCanRead ,
              groupsCanEdit: data.item.groupsCanEdit,
              groupsCanRead: data.item.groupsCanRead,
            }
            if (query.SK == '') {
              query.SK = undefined
            }
            console.log(' >> Push Data <<', query);
            //Mutation
            pushData = await API.graphql({
              query: smd_updateSmartRecord, //smd_updateSmartRecord
              variables: { 
                input:query
              },
            });
            console.log(' >> Response update <<' ,pushData);
            pushData = pushData.data.smd_updateSmartRecord
            response = pushData
            pushData = JSON.parse(pushData.data)
            this.record.item = pushData
            if (!second) {
              if (location.pathname == "/webpreview") {
                if (this.gRecord[2] == 'stay') {
                  this.setgRecord([pushData, 'update', 'stay', this.gRecord[3]])
                }else{
                  this.setgRecord([pushData, 'update', 'stay', this.gRecord[2]])
                }
                //location.reload();
              }else{
                this.setgRecord([pushData, 'update', 'stay'])
              }
            }
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        
        break;
        case 'Delete':
          query = {
            PK: data.item.PK,
            SK: data.item.SK,
            state:'0',
          }
          console.log(query);
          pushData = await API.graphql({
            query: smd_setItemState,
            variables: query,
          });
          pushData = pushData.data.smd_setItemState
          response = pushData
          console.log(pushData);
          if (data.item.shortEntity == 'NOT') {
            this.setNotifications({action:'delete', value:data.item})
          }
          loading('hidde', 'getItem')
        break;
        case 'Active':
          query = {
            PK: data.item.PK,
            SK: data.item.SK,
            state:'1',
          }
          pushData = await API.graphql({
            query: smd_setItemState,
            variables: query,
          });
          pushData = pushData.data.smd_setItemState
          response = pushData
        break;
        case 'Archive':
          query = {
            PK: data.item.PK,
            SK: data.item.SK,
            state:'2',
          }
          pushData = await API.graphql({
            query: smd_setItemState,
            variables: query,
          });
          pushData = pushData.data.smd_setItemState
          response = pushData
        break;
      }
      //console.log(' >> Successful response <<' ,response);
      if (response.smd_response.statusCode != 200) {
        popUp(response.smd_response, 'response', 'err')
        loading('hidde', 'getItem')
        return
      }
      else if (response.smd_response.popAlert == true) {
        popUp(response.smd_response, 'response', 'scc')
      }
      if (location.pathname == "/webpreview") {
        //console.log(this.gRecord);
        //location.reload();
      }
      if (this.$router.currentRoute.value.query.name == 'clietDash') {
        this.actionSave();
      }else{
        loading('close','updateRecord')
      }
      if (second == true) {
        this.recordB = null;
        response = JSON.parse(response.data)
        let data = {
          PK:response.PK,
          SK:response.SK,
          value: response.attributes[this.bRecord[3].metaJSON.fieldValue].attributeValue,
          attributes:response.attributes
        }
        let gRecord = this.gRecord
        if (this.gRecord[2] == 'stay') { this.gRecord[2] = null } 
        this.getItem(gRecord[0], gRecord[1], gRecord[2])
        $('#modal-update').modal('show')
        setTimeout(() => {
          $('#modal-updateB').modal('toggle')
          this.$refs.layout.setValueSelect(this.bRecord[2], this.bRecord[3] , this.bRecord[4], this.bRecord[5] , data)
        }, 1000);
      }
    },
    //Ui Functions
    actionModal(name, action){
      //console.log(name, action);
      switch (action) {
        case 'close':
          $('#modal-'+name).modal('hide')
        break;
        case 'email':
          $('#modal-'+name).modal('hide')
          setTimeout(() => {
            $('#modal-send').modal('toggle')
            $('#modal-send').modal('show')
          }, 200);
        break;
      }
    }
  },
  computed: {
    ...Vuex.mapState(['pageQuery',"organizationID","userPermissions", "entity","organizationPalette", "pageMeta", "pageModule", "permissions", "user", 'recordView', "dataUser", 'dashData','recordLists','dataModule','organizationData','entities','modules','gRecord','bRecord','recordSub','menu','preloadLists','moduleLayout']),
  },
  watch: {
    gRecord:{
      handler(val) {
        console.log('gRecord cambio');
        if (val != null  ) {
          this.getItem(val[0], val[1], val[2])
        }
      },
      deep: true
    },
    bRecord:{
      handler(val) {
        if (val != null) {
          this.getItem(val[0], val[1], val[2], true)
        }
      },
      deep: true
    },
    url:function(value) {
      console.log(value);
    },
  },
}
</script>
<style lang="less">
  .currency-input{
    overflow: hidden;
    position: relative;
    input{
      padding: 10px 10px 10px 20px!important;
    }
    .symbol{
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      padding: 12px 0 0 10px;
      font-size: 14px;
    }
  }
</style>